.page--category {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.category {
    &--heading {
        position: relative;

        &--image {
            width: 100%;
            height: 740px;
            background-color: $white;
            @include tablet() {
                height: 500px;
            }
            @include mobile() {
                height: 250px;
            }

            img {
                @include object(cover, 0% 50%);
            }
        }

        &--content {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            @include mobile() {
                position: relative;
                top: initial;
                left: initial;
                background-color: $color1;
                padding: 15px;
                transform: none;
            }
        }

        &--picto {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;
            @include mobile() {
                margin-bottom: 15px;
            }

            img {
                height: 100px;
                @include tablet() {
                    height: 70px;
                }
                @include mobile() {
                    height: 40px;
                }
            }
        }

        &--title {
            h1,
            .h1 {
                @include redHatDisplay38(700, $white);
                text-align: center;
                margin-bottom: 10px;
                @include tablet() {
                    @include redHatDisplay24(700, $white);
                }

                span {
                    @include redHatDisplay70(700, $white);
                    @include tablet() {
                        @include redHatDisplay38(700, $white);
                    }
                }
            }
        }

        &--description {
            text-align: center;
            max-width: $w1150;
            width: 100%;
            margin: 0 auto;
            @include tablet() {
                padding: 0 15px;
            }

            h2,
            .h2 {
                @include redHatDisplay30(700, $white);
                margin-bottom: 10px;
                @include tablet() {
                    @include redHatDisplay22(700, $white);
                }
            }

            h3,
            .h3 {
                @include redHatDisplay22(700, $white);
                margin-bottom: 10px;
                @include tablet() {
                    @include redHatDisplay18(700, $white);
                }
            }

            p,
            ul,
            div {
                @include redHatDisplay18(500, $white);
                margin: 10px 0;
                @include tablet() {
                    @include redHatDisplay14(500, $white);
                }

                strong,
                b {
                    @include redHatDisplay18(700, $white);
                    @include tablet() {
                        @include redHatDisplay14(700, $white);
                    }

                    a {
                        color: $color2;
                        letter-spacing: 0;
                    }
                }

                a {
                    @include redHatDisplay18(500, $color2);
                    @include tablet() {
                        @include redHatDisplay14(500, $color2);
                    }

                    strong,
                    b {
                        color: $color2;
                        letter-spacing: 0;
                    }
                }
            }
        }
    }

    &--footer {
        @include tablet() {
            padding: 0 15px;
        }

        .container {
            width: $w1260;
        }

        &--description {
            margin-top: 80px;

            h1,
            .h1 {
                @include redHatDisplay50(700, $color1);
                text-align: center;
                width: $w1050;
                margin: 0 auto 55px;
                padding: 0 30px;
                @include tablet() {
                    @include redHatDisplay30(700, $color1);
                }

                span {
                    color: $color2
                }
            }

            h2,
            h3,
            h4,
            h5,
            h6,
            .h2,
            .h3,
            .h4,
            .h5,
            .h6 {
                a {
                    color: $color2;
                }
            }

            h2,
            .h2 {
                @include redHatDisplay18(700, $color1);
                text-transform: uppercase;
                @include tablet() {
                    @include redHatDisplay14(700, $color1);
                }
            }

            h3,
            .h3 {
                @include redHatDisplay18(400, $color1);
                @include tablet() {
                    @include redHatDisplay14(400, $color1);
                }
            }

            h4,
            h5,
            h6,
            .h4,
            .h5,
            .h6 {
                @include redHatDisplay15(400, $color1);
                @include tablet() {
                    @include redHatDisplay12(400, $color1);
                }
            }

            p,
            ul,
            div {
                @include redHatDisplay14(400, $color1);
                margin: 20px 0;
                @include tablet() {
                    @include redHatDisplay12(400, $color1);
                }

                span {
                    @include redHatDisplay14(400, $color1);
                    @include tablet() {
                        @include redHatDisplay12(400, $color1);
                    }
                }

                b,
                strong {
                    @include redHatDisplay14(700, $color1);
                    @include tablet() {
                        @include redHatDisplay12(700, $color1);
                    }
                }

                a,
                a > * {
                    color: $color2;
                }
            }

            .span-title {
                text-transform: uppercase;
                font-weight: 700;
            }

            .bullet-points {
                padding: 0;
                list-style: none;

                li {
                    padding: 10px 0;

                    span {
                        @include redHatDisplay18(700, $color1);
                        text-transform: uppercase;
                        @include tablet() {
                            @include redHatDisplay14(700, $color1);
                        }
                    }
                }
            }
        }

        &--faq{
            &--items {
                border-top: 1px solid $grey;
            }

            &--item {
                &--header {
                    border-bottom: 1px solid $grey;
                    padding: 0 10px 10px 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &--txt {
                        @include redHatDisplay18(700, $color1);
                        text-transform: uppercase;
                        margin: 0;
                        transition: all 0.3s;
                        flex-grow: 1;
                        @include tablet() {
                            @include redHatDisplay14(700, $color1);
                        }
                    }

                    &:hover {
                        .category--footer--faq--item--header--txt {
                            color: $color2;
                        }

                        .category--footer--faq--item--picto {
                            .cls-1 {
                                stroke: $color2;
                            }
                        }
                    }
                }

                &--picto {
                    .picto-more-less {
                        .cls-1 {
                            fill: none;
                            stroke: $color1;
                            stroke-linecap: round;
                            stroke-width: 2px;
                            transition: all 0.3s;
                        }
                    }
                }

                &--content {
                    padding: 15px 70px 30px 40px;
                    border-bottom: 1px solid $grey;
                    display: none;
                    transition: all 0.3s;
                    @include mobile() {
                        padding: 0;
                    }
                }

                &--active {
                    .category--footer--faq--item--header {
                        border-color: $color2;

                        &--txt {
                            color: $color2;
                        }
                    }

                    .category--footer--faq--item--content {
                        display: block;
                    }

                    .category--footer--faq--item--picto {
                        .cls-1 {
                            stroke: $color2;
                        }

                        .line-more {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &--search {
        &--heading {
            &--title {
                @include redHatDisplay30(400, $color1);
                text-transform: uppercase;
                @include tablet() {
                    @include redHatDisplay24(400, $color1);
                }
            }

            &--txt {
                @include redHatDisplay22(700, $color2);
                @include tablet() {
                    @include redHatDisplay18(400, $color2);
                }

                a {
                    color: $color1;
                }
            }
        }
    }
}

.categories {
    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &--item {
        width: calc(50% - 25px);
        margin-top: 35px;
        padding: 10px;
        border: 1px solid $white;
        @include mobile() {
            width: 100%;
        }

        &:hover {
            border-color: $color2;
        }

        &--link {
            text-decoration: none;
        }

        &--image-picto {
            position: relative;
        }

        &--image {
            width: 100%;
            height: 385px;
            overflow: hidden;
            @include tablet() {
                height: 145px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: $white;
                opacity: 0;
                transition: all 0.3s;
            }

            .categories--item:hover & {
                &:after {
                    opacity: 0.2;
                }
            }

            img {
                @include object(contain, 0% 50%);
                transition: all 0.3s;

                .categories--item:hover & {
                    transform: scale(1.1);
                }
            }
        }

        &--picto {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 5;
            width: 140px;
            height: 85px;
            background-color: $white;
            border-top-right-radius: 15px;
            padding: 20px;

            img {
                @include object(contain);
            }
        }

        &--name {
            @include redHatDisplay38(400, $color1);
            text-transform: uppercase;
            margin-top: 5px;
        }

        &--cta {
            @include redHatDisplay18(700, $color2);
            text-transform: uppercase;
            margin-top: 10px;
        }
    }
}
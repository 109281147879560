.page--contact {
    position: relative;
}

.contact {
    &--heading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        &--image {
            width: 100%;
            height: 600px;
            background-color: $white;

            img {
                @include object();
            }
        }
    }

    &--content-form {
        display: flex;
        flex-wrap: wrap;
        padding-top: 85px;
        position: relative;
    }

    &--content {
        background-color: $color1;
        width: 50%;
        padding: 60px 90px;
        @include tablet() {
            width: 100%;
            padding: 60px 50px;
        }
        @include mobile() {
            padding: 60px 20px;
        }

        &--title {
            @include redHatDisplay38(700, $white);
            text-transform: uppercase;
            margin: 0;
        }

        &--description {
            &--text {
                padding-top: 50px;
                padding-bottom: 20px;
            }

            h2 {
                @include redHatDisplay22(700, $white);
                margin: 0;
            }

            p {
                @include redHatDisplay15(400, $white);
                @include line-height(18);
            }

            a {
                @include redHatDisplay15(700, $white);
                @include line-height(18);
                text-decoration: underline;
            }
        }
    }

    &--form {
        background-color: $white;
        width: 50%;
        padding: 60px 40px;
        box-shadow: 0 -20px 20px rgba($black, 0.16);
        @include tablet() {
            width: 100%;
        }
        @include mobile() {
            padding: 60px 0;
        }
    }

    &--showroom {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 100px;

        &--address,
        &--maps {
            width: calc(100% / 2);
            @include tablet() {
                width: 100%;
            }
        }

        &--maps {
            height: 445px;
        }

        &--address {
            padding: 0 80px;
            @include tablet() {
                padding: 0 50px;
            }
            @include mobile() {
                padding: 0 20px;
            }

            &--heading {
                @include redHatDisplay30(400, $color2);
            }

            &--item {
                margin-top: 25px;

                &--title {
                    @include redHatDisplay22(700, $color1);
                }

                &--address {
                    @include redHatDisplay14(400, $color1);
                    margin-top: 15px;
                }

                &--cta--link {
                    @include redHatDisplay14(700, $color1);
                }
            }
        }
    }

    &--trial {
        margin-top: 100px;
        @include mobile() {
            padding: 0 20px;
        }

        &--heading {
            @include redHatDisplay38(400, $color1);
            text-align: center;
            span {
                font-weight: 700;
            }
        }

        &--items {
            border-top: 1px solid $grey;
            border-bottom: 1px solid $grey;
            padding: 25px 100px;
            margin-top: 25px;
            display: flex;
            justify-content: center;
            @include tablet() {
                flex-direction: column;
            }
            @include mobile() {
                padding: 25px 0;
            }
        }

        &--item {
            width: calc(100% / 3);
            padding: 25px 50px;
            position: relative;
            @include tablet() {
                width: 100%;
            }

            &:not(:last-child):after {
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: $grey;
                @include tablet() {
                    top: initial;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                }
            }

            &--picto {
                display: flex;
                justify-content: center;
            }

            &--title {
                @include redHatDisplay18(700, $color1);
                text-align: center;
                text-transform: uppercase;
                margin-top: 20px;
            }

            &--description {
                @include redHatDisplay14(400, $color1);
                text-align: center;
                margin-top: 5px;
            }
        }
    }
}
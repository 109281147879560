.push--socialwall {
    padding: 0 20px;
    @include tablet() {
        margin-bottom: 100px;
    }
    @include mobile() {
        margin-bottom: 50px;
    }

    &--inner {
        position: relative;
        border-top: 1px solid $grey-dark;
        padding: 185px 0 0 0;
        @include tablet() {
            padding-top: 135;
        }
        @include mobile() {
            padding-top: 85px;
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: -44px;
            left: 50%;
            transform: translateX(-50%);
            width: 275px;
            height: 88px;
            padding: 0 20px;
            background-color: $white;
            @include tablet() {
                width: 200px;
            }
            @include mobile() {
                width: 150px;
            }
        }

        body.category & {
            &:before {
                height: 44px;
                background-color: $grey-light;
            }

            &:after {
                top: 0;
                height: 44px;
            }
        }
    }

    &--chain-links {
        width: 275px;
        height: 88px;
        padding: 0 20px;
        position: absolute;
        top: -44px;
        left: 50%;
        z-index: 5;
        transform: translateX(-50%);
        @include tablet() {
            width: 200px;
        }
        @include mobile() {
            width: 150px;
        }

        svg {
            width: 100%;
        }
    }

    &--header {
        text-align: center;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        @include tablet() {
            padding: 0 15px;
        }

        &--1 {
            @include redHatDisplay38(700, $color1);

            @include tablet() {
                @include redHatDisplay30(700, $color1);
            }
        }

        &--2 {
            @include redHatDisplay18(400, $color1);
            margin-top: 10px;
        }
    }

    &--items {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        margin-top: 20px;
        @include tablet() {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
        }
    }

    &--item {
        margin: 1px;
        @include tablet-up() {
            &:first-child {
                grid-column: 1 / 2;
                grid-row: 1 / 3;
            }
        }

        &--inner {
            position: relative;
        }

        &--image {
            background-color: $grey;
            width: 100%;
            aspect-ratio: 1;

            img {
                @include object();
            }
        }

        &--picto {
            position: absolute;
            top: 14px;
            right: 16px;

            svg {
                transform: scale(1.5);
                transition: all 0.3s;
            }
        }
    }
}
.push--why-choose-nihola {
    padding: 0 15px;

    .container {
        width: $w1280;
    }

    &--inner {
        position: relative;
        margin: 130px 0 170px 0;
        @include tablet() {
            margin: 100px 0;
        }
        @include mobile() {
            margin: 50px 0;
        }
    }

    &--header {
        &--1 {
            @include redHatDisplay38(700, $color1);
            text-align: center;
        }
    }

    &--body {
        @include tablet-up() {
            $wc: 1280;
            $wr: 860;
            $col2-4: get-percentage(90, $wc);
            $row1-2: get-percentage(60, $wr);
            $row2-3: get-percentage(230, $wr);
            $row3-4: get-percentage(190, $wr);
            $row4-5: get-percentage(110, $wr);
            display: grid;
            grid-template-columns: 1fr #{$col2-4} 25% #{$col2-4} 1fr;
            grid-template-rows: #{$row1-2} #{$row2-3} #{$row3-4} #{$row4-5} 1fr;
            width: #{$wc}px;
            max-width: 100%;
            height: #{$wr}px;
            margin-top: 5px;
        }
        @include tablet {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &--img {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @include tablet-up() {
            grid-column: 3/4;
            grid-row: 2/4;

            &:after,
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
            }

            &:before {
                border: 1px dashed $border-account;
                width: 0;
                height: 90px;
                top: -60px;
                left: 50%;
                transform: translateX(-50%);
            }

            &:after {
                border: 1px dashed $border-account;
                width: 0;
                height: 135px;
                bottom: -105px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        @include tablet() {
            width: 100%;
        }
    }

    &--content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 15px;
        @include tablet() {
            width: 50%;
        }
        @include mobile() {
            width: 100%;
        }

        &--1,
        &--2 {
            padding-top: 30px;
            border-bottom: 1px dashed $border-account;
            @include tablet-up() {
                grid-row: 1/3;
            }
            @include tablet() {
                padding-bottom: 30px;
            }
        }

        &--3,
        &--4 {
            padding-top: 45px;
            border-top: 1px dashed $border-account;
            @include tablet-up() {
                grid-row: 3/5;
            }
            @include tablet() {
                padding-top: 30px;
                padding-bottom: 30px;
                border-top: none;
                border-bottom: 1px dashed $border-account;
            }
        }

        &--1,
        &--3 {
            @include tablet-up() {
                grid-column: 1/3;
            }
            @include tablet($w768) {
                border-right: 1px dashed $border-account;
            }
        }

        &--2,
        &--4 {
            @include tablet-up() {
                grid-column: 4/6;
            }
        }

        &--5 {
            @include tablet-up() {
                grid-column: 2/5;
                grid-row: 5;
            }
            @include tablet() {
                width: 100%;
                padding: 30px 25%;
            }
            @include mobile() {
                padding: 30px 15px;
            }
        }
    }

    &--picto {
        width: 71px;
        height: 71px;
        background-color: $color2;
        border-radius: 50%;
    }

    &--title {
        @include redHatDisplay(700);
        color: $color1;
        @include font-size-lh(18, 23);
        text-transform: uppercase;
        margin-top: 20px;
    }

    &--txt {
        @include redHatDisplay(400);
        color: $color1;
        @include font-size-lh(16, 22);
        margin-top: 15px;
    }
}
.push--bikes {
    padding: 190px 0;
    background-color: $grey-light;
    @include tablet() {
        padding: 100px 0;
    }

    .container {
        width: $w1280;
    }

    &--inner {
        position: relative;
        @include tablet() {
            padding: 0 30px;
        }
    }

    &--txt {
        text-align: center;

        &--1 {
            @include redHatDisplay38(700, $color1);
            margin: 0;
            @include tablet() {
                @include redHatDisplay30(700, $color1);
            }
        }
    }

    &--nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-top: 1px solid $grey-dark;
        border-right: 1px solid $grey-light;
        border-bottom: 1px solid $grey-dark;
        border-left: 1px solid $grey-light;
        position: relative;
        margin-top: 30px;

        &:before {
            content: '';
            position: absolute;
            top: 15px;
            left: 50%;
            background-color: $grey-dark;
            width: 1px;
            height: calc(100% - 30px);
            transform: translateX(-50%);
        }

        &--link {
            display: flex;
            justify-content: center;
            width: 50%;
            padding: 20px;
            position: relative;

            &--inner {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @include mobile {
                    flex-direction: column;
                }
            }

            &--picto {
                margin-right: 10px;
                @include mobile {
                    margin-right: 0;
                    margin-bottom: 10px;
                }

                svg {
                    @include mobile {
                        transform: scale(0.66);
                    }

                    .cls-1 {
                        .push--bikes--nav--link.active &,
                        .push--bikes--nav--link--inner:hover & {
                            fill: $color2;
                        }
                    }
                }
            }

            &--txt {
                @include redHatDisplay22(700, $color1);
                max-width: 170px;
                transition: all 0.3s;
                @include mobile {
                    @include redHatDisplay15(700, $color1);
                }

                .push--bikes--nav--link.active &,
                .push--bikes--nav--link--inner:hover & {
                    color: $color2;
                }
            }

            &--arrow {
                position: absolute;
                bottom: -11px;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.3s;
                opacity: 0;

                .push--bikes--nav--link.active & {
                    opacity: 1;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    &--products {
        margin-top: 30px;

        &--slide {
            position: relative;
            opacity: 0;
            height: 0;
            visibility: hidden;
            transition: all 0.3s;

            &.active {
                opacity: 1;
                height: auto;
                visibility: visible;
            }
        }

        &--items {
            white-space: nowrap;
            position: relative;
            max-width: 100%;
            padding-bottom: 45px;

            .slick-list {
                overflow: hidden;
            }

            .slick-track {
                display: flex;
            }
        }

        &--dots {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 10px;
                border: 1px solid $color1;
                border-radius: 50%;
                cursor: pointer;

                button {
                    text-indent: -9999px;
                    width: 10px;
                    height: 10px;
                    padding: 0;
                    border: none;
                    background-color: $grey-dark;
                    border-radius: 50%;
                    transition: all 0.3s;
                    cursor: pointer;
                }

                &.slick-active {
                    button {
                        background-color: $color2;
                    }
                }
            }
        }

        &--arrows {
            @include mq(null, 1550px) {
                display: none;
            }
        }

        &--arrow {
            position: absolute;
            top: 50%;
            z-index: 9;
            transform: translateY(-50%);
            cursor: pointer;

            &--prev {
                left: -52px;
            }

            &--next {
                right: -52px;
                transform: scale(-1) translateY(49px);
            }
        }
    }
}
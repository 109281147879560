.page--realisations {
    padding: 0 15px;
    &[data-is-masonry="0"] {
        .realisations--grid--sizer {
            display: none;
        }
    }
    .realisations {
        &--grid {
            display: flex;
            flex-wrap: wrap;
            margin: 30px -15px 0 -15px;
            &--sizer,
            &--item {
                width: calc(100% / 3);
                @include mobile() {
                    width: 50%;
                }
                @include mq(null, 480px) {
                    width: 100%;
                }
            }
            &--item {
                padding: 15px;
            }
        }
        &--picture {
            overflow: hidden;
            img {
                transition: all 0.3s;
            }
            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
        &--caption {
            text-align: center;
            a {
                color: $color2;
                text-decoration: none;
                transition: all 0.3s;
                &:hover {
                    color: $color1;
                }
            }
        }
    }
    .loader-ellips {
        font-size: 20px;
        position: relative;
        width: 4em;
        height: 1em;
        margin: 10px auto;
    }
    .loader-ellips__dot {
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 0.5em;
        background: $color1;
        position: absolute;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        &:nth-child(1), &:nth-child(2) {
            left: 0;
        }
        &:nth-child(3) {
            left: 1.5em;
        }
        &:nth-child(4) {
            left: 3em;
            animation-name: reveal;
            animation-direction: reverse;
        }
        &:nth-child(1) {
            animation-name: reveal;
        }
        &:nth-child(2), &:nth-child(3) {
            animation-name: slide;
        }
    }
    .pagination__next {
        display: none;
    }
}
.module-willowpinterest-WillowPinterest {
    .fancybox-skin {
        background-color: $white;
    }
    .fancybox-title-float-wrap {
        position: relative;
        bottom: initial;
        right: initial;
        margin-bottom: 0;
        .child {
            @include redHatDisplay14(400, $color2);
            text-shadow: none;
            background-color: $white;
            margin-top: 15px;
            margin-right: 0;
            white-space: initial;
        }
    }
}
@keyframes reveal {
    from { transform: scale(0.001); }
    to { transform: scale(1); }
}

@keyframes slide {
    to { transform: translateX(1.5em) }
}
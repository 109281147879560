.push--links {
    &--items {
        width: $w1920;
        max-width: 100%;
        margin: 0 auto;
    }

    &--item {
        $themes : (white, $white), (color1, $color1), (color2, $color2);
        $widthImage: 61.3%;
        display: flex;
        align-items: center;
        height: 600px;
        @include tablet() {
            flex-direction: column-reverse;
            height: auto;
        }

        @each $theme, $color in $themes {
            &[data-color="#{$theme}"] {
                background-color: $color;
            }
        }

        &:nth-child(2n+1) {
            flex-direction: row-reverse;
            @include tablet() {
                flex-direction: column-reverse;
            }
        }

        &--image {
            flex: 0 0 100%;
            @include tablet-up() {
                flex: 0 0 #{$widthImage};
                height: 100%;
                clip-path: ellipse(50% 90% at 40% 50%);

                .push--links--item:nth-child(2n+1) & {
                    clip-path: ellipse(50% 90% at 60% 50%);
                }
            }
            @include tablet() {
                aspect-ratio: 16/9;
            }

            img {
                @include object(cover, 100% 25%);
                .push--links--item:nth-child(2n+1) & {
                    @include object(cover, 0% 25%);
                }
            }
        }

        &--content {
            flex: 0 1 100%;
            display: flex;
            @include tablet() {
                padding: 50px 15px;
            }

            .push--links--item:nth-child(2n+1) & {
                justify-content: flex-end;
            }

            @include tablet-up() {
                &--inner {
                    max-width: 75%;
                }
            }
        }

        &--title {
            &--1 {
                @include redHatDisplay50(700, $white);
                @include tablet() {
                    @include redHatDisplay38(700, $white);
                }
                @include mobile() {
                    @include redHatDisplay30(700, $white);
                }

                .push--links--item[data-color="white"] & {
                    color: $color1;
                }
            }

            &--2 {
                @include redHatDisplay50(700, $white);
                @include tablet() {
                    @include redHatDisplay38(700, $white);
                }
                @include mobile() {
                    @include redHatDisplay30(700, $white);
                }

                .push--links--item[data-color="white"] & {
                    color: $color2;
                }
            }
        }

        &--txt {
            @include redHatDisplay18(400, $white);
            @include line-height(30);
            margin-top: 35px;
            max-width: 410px;
            @include tablet() {
                @include redHatDisplay15(400, $white);
            }
            @include mobile() {
                @include redHatDisplay14(400, $white);
            }

            .push--links--item[data-color="white"] & {
                color: $color1;
            }
        }

        &--cta {
            margin-top: 30px;
        }
    }
}
@import "partials/mixins";
@import "partials/variables";
@import "partials/typography";
@import "partials/functions";
@import "partials/flex-content";
@import "plugins/advancedtopmenu";
@import "plugins/fancybox";
@import "plugins/jquery-footable";
@import "plugins/colissimo";
//@import "plugins/tarteaucitron";
/* ----------------------------- */
@import "fontawesome/fontawesome";
//@import "fontawesome/solid";
//@import "fontawesome/regular";
//@import "fontawesome/brands";
@import "fontawesome/light";
//@import "fontawesome/duotone";
/* ----------------------------- */
@import "components/header";
@import "components/footer";
@import "components/product";
@import "components/category";
@import "components/pagination";
@import "components/push";
@import "components/cta";
@import "components/contact";
@import "components/account";
@import "components/checkout";
@import "components/breadcrumb";
@import "components/notifications";
@import "components/modal";
@import "components/form-fields";
@import "components/psgdpr";
@import "components/cms";
@import "components/404";
@import "components/search";
@import "components/wordpress";
@import "components/my-amsterdamer";
@import "components/rgpd";
@import "components/realisations";

:root {
    --vh: 100vh;
    --banner-height: 0;
}

*,
*:after,
*:before {
    @include box-sizing(border-box);
}

.visuallyhidden {
    @include visuallyhidden;
}

.clearfix {
    @include clearfix;
}

body {
    @include redHatDisplay18(400, $color1);
    margin: 0;

    &.menu-open {
        overflow: hidden;
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.visuallyhidden,
.visually-hidden {
    @include visuallyhidden;
}

.french-flag {
    background-color: $white;
    width: 42px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% / 3);
        height: 100%;
    }

    &:before {
        background-color: $blue;
        left: 0;
        width: calc(100% / 3);
    }

    &:after {
        background-color: $color2;
        right: 0;
    }
}

.container {
    width: $w1470;
    max-width: 100%;
    margin: 0 auto;

    .header--bottom & {
        position: relative;
    }

    @include tablet-up() {
        padding: 0 15px;
    }
    .category--heading &,
    .footer & {
        position: relative;
    }
    @include tablet() {
        .cms &,
        .pagenotfound & {
            padding: 0 15px;
        }
    }
}

.main {
    margin-top: 120px;
    position: relative;
    @include tablet() {
        margin-top: 70px;
    }

    .body--product--configurator &,
    body.product & {
        @media print {
            margin-top: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: -145px;
        left: 0;
        width: 100%;
        height: 145px;
        background-color: $color1;
        @media print {
            display: none;
        }
        @include tablet() {
            height: 70px;
        }
    }
}

.page--top {
    text-align: center;
    position: absolute;
    right: 10%;
    bottom: 0;
    z-index: 1;
    width: 82px;
    cursor: pointer;
    @include small-desktop() {
        display: none;
    }

    .product--resume & {
        right: initial;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
    }

    &--picto {
        transition: all 0.3s;

        .page--top:hover & {
            transform: translateY(-10px);

            .picto-page-top {
                .cls-1 {
                    stroke: $color2;
                    stroke-width: 4px;
                }
            }
        }
    }

    &--title {
        @include redHatDisplay12(400, $color1);
    }
}

.unvisible {
    display: none;
}

.alert-danger {
    border: 2px dashed $error;
    margin: 20px 0;
    padding: 20px;

    p {
        color: $error;
    }

    ol {
        margin: 0;
        padding: 0 0 0 15px;

        li {
            @include redHatDisplay15(400, $error);
        }
    }
}

.return-to-search {
    padding-bottom: 15px;
    a {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        p {
            color: $color2;
            text-decoration: underline;
        }
        &:hover {
            .picto-back {
                transform: translateX(-10px);
            }
        }
    }
    .picto-back {
        margin-right: 10px;
        transition: all 0.3s;
        .cls-1 {
            fill: $color1;
        }
    }
}

.tacReCAPTCHA {
    margin-top: 20px;
}

.st_banner_block {
    picture {
        display: flex;
        justify-content: center;
    }
}

#___ratingbadge_0 {
    display: none !important;
}

#storedelivery {
    display: none;
    transition: all 0.3s;
    &.show {
        display: block;
    }
}
.storedelivery {
    &--content {
        &--map {
            margin-top: 20px;
            width: 100%;
            padding-bottom: calc(100% / 3);
            position: relative;
            #map {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
@mixin historyDetail() {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        @include redHatDisplay14(700, $color2);
        padding: 0;
        transition: all 0.3s;

        &:not(:last-child) {
            text-align: center;
            width: 20px;
            height: 20px;
            border: 2px solid $color2;
            border-radius: 3px;
            margin-right: 5px;
            transition: all 0.3s;
        }
    }
}

@include mobile() {
    .page--authentification {
        padding-top: 50px;
    }
}

.authentification {
    &--header {
        margin-bottom: 105px;
        @include mobile() {
            margin-bottom: 50px;
        }

        &--title {
            @include redHatDisplay50(700, $color1);
            text-align: center;
            text-transform: uppercase;
            margin: 0;
            @include tablet() {
                @include redHatDisplay38(700, $color1);
            }

            span {
                color: $color2;
            }
        }
    }

    &--separator {
        width: 1px;
        background-color: $grey-dark;
    }

    &--forms {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: $w1050;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 35px;
    }

    &--form {
        margin: 0 50px;
        width: calc(50% - 101px);
        max-width: 100%;
        @include tablet() {
            width: 100%;
        }

        .contact--form &,
        .authentification--forgot-password & {
            width: auto;
        }

        .authentification--forgot-password & {
            margin: 0;
        }

        @include tablet() {
            &--create {
                margin-top: 50px;
            }
        }
    }

    &--forgot-password {
        max-width: 540px;
        margin: 0 auto;

        &--text p {
            @include redHatDisplay14(400, $color1);
        }
    }
}

.account {
    &--form {
        .page--account--identity &,
        .page--account--address &,
        .page--account--thread &,
        .page--account--reviews-adventures & {
            max-width: 50%;
            @include tablet() {
                max-width: 75%;
            }
            @include mobile() {
                max-width: 100%;
            }
        }

        &--header {
            display: flex;
            justify-content: center;
            margin-bottom: 35px;

            &--title {
                @include redHatDisplay38(400, $color2);
                @include tablet() {
                    @include redHatDisplay30(400, $color2);
                }
            }

            &--picto {
                margin-right: 20px;

                .picto-user {
                    width: 35px;
                }
            }
        }

        &--content {
            border: none;
            padding: 0;
            margin: 0;
        }

        &--row {
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .authentification--form--create &,
            .contact--form & {
                margin-top: 20px;
            }

            .form-group {
                display: table;
                width: 100%;
                position: relative;
                border-collapse: collapse;
            }

            .input-group-btn {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 5;
                width: 40px;
                button {
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    border: 1px solid $border-account;
                    border-left: none;
                    padding: 0;
                    cursor: pointer;
                }
            }

            &--checkbox,
            &--radio {
                &.order_cb {
                    width: 30px;
                }

                label {
                    position: relative;
                    height: 20px;
                    margin-bottom: 0;

                    .page--account--reviews-adventures & {
                        height: auto;
                    }

                    input {
                        border: 0;
                        clip: rect(0 0 0 0);
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        padding: 0;
                        overflow: hidden;
                        position: absolute;

                        &:checked + span svg {
                            display: block;
                        }
                    }

                    span {
                        color: $white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        height: 20px;
                        background-color: $color2;
                        border: 1px solid $border-account;
                        position: absolute;
                        left: 0;

                        svg {
                            display: none;
                        }
                    }

                    p {
                        @include redHatDisplay14(400, $color1);
                        text-transform: initial;
                        margin: 0 0 0 30px;

                        a {
                            color: $color2;
                        }
                    }
                }
            }

            &--radio {
                label {
                    span {
                        border-radius: 50%;
                    }
                }
            }
        }

        &--txt {
            @include redHatDisplay14(700, $color2);
        }

        &--label {
            @include redHatDisplay14(700, $color1);
            text-transform: uppercase;
            margin-bottom: 5px;
            display: block;
            &.file {
                display: flex;
                align-items: center;

                svg {
                    margin-left: 15px;
                }

                span {
                    @include redHatDisplay12(400, $color2);
                    text-transform: initial;

                    &.filename {
                        margin-left: 15px;
                    }
                }
            }
        }

        &--input {
            @include redHatDisplay15(400, $color1);
            width: 100%;
            height: 42px;
            border: 1px solid $border-account;
            padding: 10px;
            @include placeholder($grey-dark);

            &--file {
                display: none;
            }
        }

        &--textarea {
            @include redHatDisplay14(400, $color1);
            font-style: italic;
            width: 100%;
            border: 1px solid $border-account;
            padding: 10px;
            @include placeholder($grey-dark);
        }

        &--selects {
            display: flex;
            justify-content: space-between;
        }

        &--select {
            @include redHatDisplay14(400, $color1);
            font-style: italic;
            width: 100%;
            height: 100%;
            padding-top: 10px;
            padding-right: 35px;
            padding-bottom: 10px;
            padding-left: 10px;
            background-color: $white;
            appearance: none;
            border: none;

            &--div {
                width: 100%;
                height: 42px;
                border: 1px solid $border-account;
                position: relative;

                .account--form--selects & {
                    width: 30%;
                }

                .picto-pagination {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: rotate(-90deg) translateX(100%);
                    pointer-events: none;

                    .cls-1 {
                        fill: $color1;
                    }
                }
            }
        }

        &--button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 42px;
            background-color: $color2;
            border: 2px solid $color2;
            transition: all 0.3s;
            margin-top: 10px;

            &:hover {
                background-color: $white;
            }

            &--txt {
                @include redHatDisplay18(700, $white);
                text-transform: uppercase;
                transition: all 0.3s;

                .account--form--button:hover & {
                    color: $color2;
                }
            }
        }

        &--lost-password,
        &--back-to-login {
            text-align: center;
            margin-top: 15px;

            &--link {
                @include redHatDisplay14(400, $color1);
            }
        }

        &--newsletter {
            &--item {
                border: 1px solid $border-account;
                padding: 20px;
                margin-bottom: 20px;

                .contact--form & {
                    max-width: 290px;
                }
            }

            &--label {
                position: relative;

                input {
                    border: 0;
                    clip: rect(0 0 0 0);
                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;
                    overflow: hidden;
                    position: absolute;

                    &:checked + span svg {
                        display: block;
                    }
                }

                span {
                    color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    background-color: $color2;
                    border: 1px solid $border-account;
                    position: absolute;
                    left: 0;

                    svg {
                        display: none;
                    }
                }

                p {
                    @include redHatDisplay22(700, $color1);
                    text-transform: uppercase;
                    margin: 0 0 0 30px;

                    .contact--form & {
                        @include redHatDisplay14(700, $color1);
                    }
                }
            }

            &--text {
                p {
                    @include redHatDisplay14(400, $color1);
                }
            }
        }

        &--text {
            @include redHatDisplay15(400, $color1);
        }

        &--privacy {
            p {
                @include redHatDisplay14(400, $grey-dark);
                font-style: italic;

                a {
                    @include redHatDisplay14(400, $grey-dark);
                    font-style: italic;
                    text-decoration: underline;
                }
            }
        }
    }

    &--notice {
        &--items {
            display: flex;
            flex-wrap: wrap;
        }

        &--item {
            width: calc(100% / 3 - 30px);
            background-color: $grey;
            margin: 15px;
            @include mobile() {
                width: 100%;
            }

            @include mobile-up() {
                &:nth-child(3n+1) {
                    margin-left: 0;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }

            &--inner {
                padding: 20px 30px;
            }

            a {
                text-decoration: none;
            }
        }

        &--title {
            @include redHatDisplay22(700, $color2);
            margin-top: 0;
        }

        &--txt {
            @include redHatDisplay14(400, $color1);
        }

        &--action {
            @include redHatDisplay14(400, $color2);
        }
    }

    &--content {
        background-color: $grey;
        padding: 20px 30px;
        margin-bottom: 30px;

        &.block-comment-adventure-detail {
            margin-top: 20px;
        }

        &--title {
            @include redHatDisplay30(400, $color2);
            margin-top: 0;
            margin-bottom: 20px;
        }

        &--subtitle {
            @include redHatDisplay22(400, $color2);

            &.total {
                margin-top: 20px;
            }
        }

        &--txt {
            @include redHatDisplay14(400, $color1);

            &.alert {
                color: $color1;
                border: 2px dashed $color1;
                margin: 20px 0;
                padding: 20px;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            span {
                @include redHatDisplay18(400, $color1);
            }
        }

        &--action {
            a {
                @include redHatDisplay14(400, $color2);
            }
        }
    }

    &--order-details {
        margin-top: 20px;
        background-color: $grey;
        padding: 20px 30px;

        .product-detail {
            display: none;
        }

        .bloc_adresses {
            margin: 20px 0;
        }

        #sendOrderMessage,
        .info-order,
        #carriers-table {
            display: none;
        }
    }
}

.page--account {
    @include tablet() {
        padding: 0 15px 50px 15px;
    }
    &--inner {
        display: flex;
        flex-wrap: wrap;
    }

    &--nav {
        width: 25%;
        @include tablet() {
            width: 100%;
        }
    }

    &--content {
        width: 75%;
        padding-left: 50px;
        @include tablet() {
            width: 100%;
            padding-left: 0;
            padding-top: 50px;
        }
    }
}

.account {
    &--header {
        margin-bottom: 65px;

        &--title {
            @include redHatDisplay50(700, $color1);
            text-align: center;
            text-transform: uppercase;
            margin: 0;

            span {
                color: $color2;
            }
        }
    }

    &--title {
        @include redHatDisplay38(400, $color2);
        margin-top: 0;
        margin-bottom: 20px;
    }

    &--subtitle {
        @include redHatDisplay22(400, $color2);
        margin-top: 0;
        margin-bottom: 20px;
    }

    &--txt {
        @include redHatDisplay14(400, $color1);

        a {
            color: $color2;
            text-decoration: underline;
        }
    }

    &--actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
    }

    &--action {
        margin: 0 25px;

        button {
            cursor: pointer;
        }
    }
}

#account-menu-overlay,
#account-menu-trigger {
    transition: all 0.3s;
    @include tablet-up() {
        display: none;
    }
}

#account-menu-trigger {
    text-align: right;
    position: relative;
    a {
        @include redHatDisplay22(400, $color2);
        text-align: center;
        position: relative;
        border: 3px solid $color2;
        border-radius: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        &:before {
            @include redHatDisplay22(400, $color2);
            content: 'Menu compte';
            position: absolute;
            top: 50%;
            right: 110%;
            width: 150px;
            transform: translateY(-50%);
        }
    }
}

.account-nav {
    @include tablet() {
        position: fixed;
        top: 130px;
        bottom: 0;
        left: 0;
        z-index: 51;
        width: calc(100% - 70px);
        padding: 20px;
        overflow: auto;
        display: none;
        transition: all 0.3s;
        background-color: $white;
        box-shadow: 0 0 5px rgba($black, 0.5);

        .account-menu-open & {
            display: block;
        }
    }
    @include mobile() {
        top: 70px;
    }
}

.myaccount-link-list {
    padding: 0;
    margin: 0;

    li {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $color1;
        list-style: none;
        position: relative;

        &.current,
        &:hover {
            a {
                color: $color2;
            }
        }

        &.current {
            &:before {
                height: 50%;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 2px;
            height: 0;
            background-color: $color2;
            transform: translateY(-50%);
            transition: all 0.3s;
        }

        a {
            @include redHatDisplay15(400, $color1);
            text-decoration: none;
        }
    }
}

.address {
    &--items {
        display: flex;
        flex-wrap: wrap;
    }

    &--item {
        width: calc(50% - 30px);
        background-color: $grey;
        padding: 20px 30px;
        margin: 15px 0;
        @include mobile() {
            width: 100%;
        }

        @include mobile-up() {
            &:nth-child(2n+1) {
                margin-right: 15px;
            }

            &:nth-child(2n) {
                margin-left: 15px;
            }
        }
    }

    &--title {
        @include redHatDisplay22(700, $color2);
        margin-top: 0;
    }

    &--txt {
        @include redHatDisplay14(400, $color1);
        margin: 0;
    }

    &--actions {
        display: flex;
        margin-top: 15px;
    }

    &--action {
        margin-right: 15px;

        a {
            @include redHatDisplay14(400, $color1);

            &.modify {
                color: $color2;
            }
        }
    }

    .fancybox-inner {
        .message {
            @include redHatDisplay14(400, $color1);

            h2 {
                @include redHatDisplay22(700, $color2);
            }

            a {
                color: $color2;
            }
        }
    }
}

#block-history,
#block-comments,
#block-comments2 {
    @include mobile() {
        overflow: auto;
    }
    #order-detail-content {
        @include mobile() {
            overflow: auto;
        }
        tfoot {
            border-top: 2px solid $color1;

            tr {
                td {
                    @include redHatDisplay15(700, $color1);

                    .price,
                    .price-shipping {
                        @include redHatDisplay22(300, $color1);
                        text-align: right;
                        display: block;
                    }
                }

                &.totalprice {
                    td {
                        @include redHatDisplay22(700, $color2);
                        text-transform: uppercase;

                        .price,
                        .price-shipping {
                            @include redHatDisplay30(300, $color2);
                        }
                    }
                }
            }

        }
        tbody {
            tr {
                td {
                    &.reference {
                        width: 150px;
                    }

                    &.price {
                        text-align: center;
                        width: 120px;
                    }

                    &.return_quantity {
                        text-align: center;

                        input,
                        .return_quantity_buttons {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .table-wrap {
        @include mobile() {
            overflow: auto;
        }
    }
    #discount-list,
    #order-detail-content,
    #order-list,
    #product-comments,
    .table_block {
        width: 100%;
        border-collapse: collapse;

        th {
            @include redHatDisplay18(400, $color2);
            text-align: left;
            white-space: nowrap;
            vertical-align: middle;
            padding: 5px;
        }

        tbody {
            tr {
                background-color: $grey;
                border-top: 2px solid $border-account;
                transition: all 0.3s;

                td {
                    @include redHatDisplay14(400, $color1);
                    padding: 10px 5px;

                    .color-myaccount,
                    .link-button {
                        @include redHatDisplay14(400, $color1);
                        text-decoration: none;
                        transition: all 0.3s;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.history_detail,
                    &.history_delete {
                        a,
                        div {
                            @include historyDetail;
                        }
                    }

                    .footable-row-detail-value {
                        a[id*="history_detail_"] {
                            @include historyDetail;

                            span {
                                &:nth-child(2) {
                                    &:not(:last-child) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &.history_detail {
                        a,
                        div {
                            span {
                                &:nth-child(2) {
                                    &:not(:last-child) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .show-detail {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        span {
                            @include redHatDisplay14(700, $color2);
                            padding: 0;
                            transition: all 0.3s;

                            &:not(:last-child) {
                                text-align: center;
                                width: 20px;
                                height: 20px;
                                border: 2px solid $color2;
                                border-radius: 3px;
                                margin-right: 5px;
                                transition: all 0.3s;
                            }

                            &:nth-child(2) {
                                &:not(:last-child) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #discount-list,
    #order-list,
    #product-comments {
        tbody {
            tr {
                &:hover,
                &.detail-open {
                    background-color: $color2;

                    .account--txt {
                        color: $white;
                        a {
                            color: $color1;
                        }
                    }

                    td {
                        color: $white;

                        .color-myaccount,
                        .link-button {
                            color: $white;
                        }

                        &.history_detail {
                            a,
                            div {
                                span {
                                    color: $white;
                                    border-color: $white;
                                }
                            }
                        }
                    }
                }

                &.detail-open {
                    &.history_detail {
                        a,
                        div {
                            span {
                                &:first-child {
                                    display: none;
                                }

                                &:nth-child(2) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #product-comments {
        margin-top: 20px;
    }
}

#id_view_messages,
#id_new_adventure_form,
#id_new_comment_form,
#id_view_comment,
#id_view_adventure {
    border: 2px solid $color1;
    padding: 20px;
}

#id_new_adventure_form {
    border: none;
}

#id_new_comment_form {
    .notes-echelle {
        display: flex;
        align-items: center;
    }

    .note-off {
        margin-right: 10px;
    }
}

#id_view_adventure,
#id_view_comment {
    .row {
        margin: 20px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .avis {
        @include redHatDisplay15(400, $color1);
        text-transform: uppercase;
    }

    .date_note_etat {
        font-size: 0;

        > div {
            display: inline-block;
            vertical-align: top;
            width: 33.333%;

            span.label {
                @include redHatDisplay14(700, $color2);
                margin-right: 4px;
            }

            span {
                @include redHatDisplay14(400, $color1);
                display: inline-block;
                vertical-align: middle;
            }
        }

        .note {
            display: flex;

            &.note-1 {
                background-position: 0 -13px;
            }

            &.note-2 {
                background-position: 0 -26px;
            }

            &.note-3 {
                background-position: 0 -39px;
            }

            &.note-4 {
                background-position: 0 -52px;
            }

            &.note-5 {
                background-position: 0 -65px;
            }
        }
    }

    .titre_avis {
        @include redHatDisplay14(700, $color2);

        span {
            color: $color1;
        }
    }

    .avis_desc {
        @include redHatDisplay14(400, $color1);
    }

    .photos_titre {
        @include redHatDisplay14(700, $color2);
    }

    .photos_visu {
        overflow: hidden;

        ul {
            padding: 0;
            margin: 0 -20px;
            list-style: none;
            font-size: 0;

            li {
                display: inline-block;
                vertical-align: top;
                padding: 0 20px;
                width: 33.333%;
                text-align: center;
            }
        }
    }

    .action a {
        @include redHatDisplay14(400, $color2);
    }
}

#id_view_messages {
    .wma-title {
        @include redHatDisplay14(400, $color2);
    }

    .wma-message-wrap {
        .wma-message {
            padding: 0 0 20px 0;
            margin: 0 0 20px 0;
            border-bottom: 1px dashed $border-account;

            .wma-message-header {
                .wma-message-author {
                    @include redHatDisplay14(400, $color2);
                }

                .wma-message-date {
                    @include redHatDisplay14(400, $color2);
                }
            }

            .wma-message-content {
                @include redHatDisplay15(400, $color1);
            }

            .wma-message-images a {
                @include redHatDisplay15(400, $color2);
            }
        }
    }
}

#returnOrderMessage {
    margin-top: 30px;
}
.page--cms {
    .container {
        width: 1100px;
    }
}
.cms--content {
    @include mobile() {
        padding-top: 20px;
    }
    &--actions {
        display: flex;
        align-items: center;

        &--txt {
            @include redHatDisplay14(400, $color1);
        }
    }

    &--category {
        &--header {
            &--title {
                @include redHatDisplay38(400, $color2);

                a {
                    color: $color2;
                }
            }

            &--txt {
                @include redHatDisplay14(400, $color1);
            }
        }
    }

    &--subcategories,
    &--pages {
        &--title {
            @include redHatDisplay18(400, $color1);
            text-transform: uppercase;
        }

        &--item--link {
            @include redHatDisplay14(400, $color1);

            a {
                color: $color1;
            }
        }
    }

    &--error {
        padding-top: 50px;

        .pagenotfound & {
            padding-top: 0;
        }

        &--txt {
            @include redHatDisplay18(400, $error);
            text-align: center;
        }

        &--img {
            .pagenotfound & {
                display: flex;
                justify-content: center;
            }
        }
    }

    &--description {
        h1,
        .h1 {
            @include redHatDisplay38(400, $color2);
            @include tablet() {
                @include redHatDisplay30(400, $color2);
            }
        }

        h2,
        .h2 {
            @include redHatDisplay24(400, $color2);
            @include tablet() {
                @include redHatDisplay22(400, $color2);
            }
        }

        h3,
        h4,
        h5,
        h6,
        .h3,
        .h4,
        .h5,
        .h6 {
            @include redHatDisplay18(400, #dd2c32);
        }

        p,
        ul,
        div {
            @include redHatDisplay(400);
            @include font-size-lh(14, 18);
            color: $color1;
            margin: 20px 0;

            span {
                @include redHatDisplay(400);
                @include font-size-lh(14, 18);
                color: $color1;
            }

            b,
            strong {
                @include redHatDisplay(700);
                @include font-size-lh(14, 18);
                color: $color1;
            }

            a,
            a > * {
                color: $color2;
            }
        }

        div[class*="elementor"] {
            margin: 0;
            p,
            div,
            ul {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                padding-left: 20px;
                position: relative;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                }

                &:before {
                    top: 3px;
                    @include triangle(14, 14, right, $color1);
                }

                &:after {
                    @include triangle(4, 14, right, $white);
                    top: 3px;
                }
            }
        }

        ol {
            padding: 0;
            counter-reset: section;
            list-style: none;

            li {
                position: relative;
                padding: 0 0 0 20px;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $color2;
                    counter-increment: section;
                    content: counters(section, ".") ".\00a0";
                    display: inline-block;
                    min-width: 20px;
                    text-align: center;
                }
            }
        }

        hr {
            border: none;
            height: 1px;
            background-color: $color1;
        }

        blockquote {
            @include redHatDisplay15(400, $color1);
            text-align: center;
            font-style: italic;
            position: relative;
            padding: 20px 0;

            &:before,
            &:after {
                content: "";
                height: 1px;
                background-color: $black;
                position: absolute;
                left: 25%;
                right: 25%;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }

            p {
                &:before {
                    content: "\00AB\00a0";
                }

                &:after {
                    content: "\00a0\00BB";
                }
            }
        }

        .span-title {
            text-transform: uppercase;
            font-weight: 700;
        }

        .bullet-points {
            padding: 0;
            list-style: none;

            li {
                padding: 10px 0;

                span {
                    @include redHatDisplay18(700, $color1);
                    text-transform: uppercase;
                }
            }
        }
    }
}
.elementor-column-gap-default > .elementor-row > .elementor-column > .elementor-element-populated .elementor-element-populated {
    padding: 0;
}
.push--intro {
    position: relative;
    z-index: 9;

    &--item {
        position: relative;
        width: 100%;
        height: calc(100vh - 195px);

        &--image,
        &--video {
            display: flex;
            justify-content: center;
            width: $w1920;
            max-width: 100%;
            margin: 0 auto;
            height: 100%;
            background-color: $white;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            img,
            video,
            iframe {
                @include object(cover, 50% 25%);
            }
        }

        &--content {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 50px;
            @include mobile() {
                padding: 0 30px;
            }
            .container {
                width: $w1260;
            }
        }

        &--txt {
            &--1 {
                @include redHatDisplay70(700, $white);
                text-align: center;
                text-shadow: 0 0 5px rgba($black, 0.75);
                @include mobile() {
                    @include redHatDisplay38(700, $white);
                }
            }
        }

        &--cta {
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
    }

    &--cta {
        position: absolute;
        bottom: 0;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, 35px);
        transition: all 0.3s;
        @include tablet() {
            transform: translate(-50%, 35px) scale(0.9);
        }
        @include mobile() {
            transform: translate(-50%, 35px) scale(0.75);
        }

        &:hover {
            transform: translate(-50%, 45px);
        }
    }

    &--arrows {
        @include mq(768px, 1550px) {
            display: none;
        }
    }

    &--arrow {
        $top: calc(-50% - 44px);
        position: absolute;
        top: 50%;
        z-index: 999;
        transform: translateY(#{$top});
        cursor: pointer;

        &--prev {
            left: 165px;
            @include mobile {
                left: 0;
                top: 0;
                transform: none;
            }
        }

        &--next {
            right: 165px;
            transform: scale(-1) translateY(92px);
            @include mobile {
                right: 0;
                top: 0;
                transform: scale(-1);
            }
        }

        svg {
            path {
                stroke: $color1;
                @include mobile-up() {
                    stroke: $white;
                }
            }
            @include mobile {
                transform: scale(0.5);
            }
        }
    }
}
.adtm_menu_container {
    height: 100%;
    @include tablet() {
        display: none;
        position: absolute;
        top: 0;
        left: -15px;
        right: -15px;
        width: calc(100% + 30px);
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100 - var(--banner-height));
        background-color: $color1;
        padding: 100px 50px 30px 50px;
        overflow-y: auto;
        transition: all 0.3s;

        &.open {
            display: block;
        }
    }
}

.advtm_menu_toggle {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    @include tablet-up() {
        display: none;
    }
}

#adtm_menu {
    height: 100%;

    .adtm_menu_close {
        position: absolute;
        top: 25px;
        right: 40px;
        cursor: pointer;
        @include tablet-up() {
            display: none;
        }
    }

    #adtm_menu_inner {
        height: 100%;
    }

    ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        @include tablet() {
            flex-direction: column;
            height: calc(100% - 100px - (var(--banner-height) * 2));
            overflow-y: auto;
            overflow-x: hidden;
        }
        @include mobile() {
            height: calc(100% - 130px);
        }

        .li-niveau1 {
            padding: 20px 15px 35px 15px;
            height: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            max-width: 200px;
            @include tablet() {
                position: relative;
                width: 100%;
                max-width: 100%;
                height: auto;
                padding: 20px 15px;
                border-bottom: 1px solid $grey;
            }

            .a-niveau1 {
                @include redHatDisplay15(400, $white);
                text-decoration: none;
                display: flex;
                position: relative;
                @include tablet() {
                    width: 100%;
                }

                &:after {
                    content: '';
                    width: 0;
                    height: 3px;
                    background-color: $color2;
                    border-radius: 100px;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.3s;
                    @include tablet() {
                        width: 3px;
                        height: 0;
                        top: 50%;
                        bottom: initial;
                        left: -10px;
                        transform: translateY(-50%);
                    }
                }

                .advtm_menu_span {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    @include tablet() {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                    }
                }

                img,
                svg {
                    object-fit: contain;
                    object-position: 50% 50%;
                    width: auto;
                    max-width: 60px;
                    height: 25px;
                    margin: 0 auto 10px auto;
                    transition: all 0.3s;
                    @include tablet() {
                        margin: 0 15px 0 0;
                        flex: 0 0 48px;
                        object-fit: scale-down;
                        max-height: 20px;
                    }

                    body.scrolled & {
                        display: none;
                    }
                }
            }

            .adtm_sub {
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
                background-color: $color1;
                transition: all 0.3s;
                @include tablet() {
                    display: none;
                    position: relative;
                    top: 0;
                    padding: 20px 0 45px 0;
                }
                @include tablet-up() {
                    opacity: 0;
                    height: 0;
                    pointer-events: none;
                }

                .columnWrapTable {
                    display: flex;
                    flex-wrap: wrap;
                    $gutterTd: 30;

                    .adtm_column_wrap_td,
                    .adtm_column_wrap_td_width_100 {
                        width: calc(100% - 90px);
                        margin: 0 #{$gutterTd}px;
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 4;
                            column-gap: 40px;
                            @include tablet() {
                                column-count: 2;
                            }
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_20 {
                        width: calc(100% / 5 - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 1;
                            column-gap: 0;
                        }
                    }

                    .adtm_column_wrap_td_width_25 {
                        width: calc(25% - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 1;
                            column-gap: 0;
                        }
                    }

                    .adtm_column_wrap_td_width_30 {
                        width: calc(30% - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 1;
                            column-gap: 0;
                        }
                    }

                    .adtm_column_wrap_td_width_33 {
                        width: calc(100% / 3 - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 1;
                            column-gap: 0;
                        }
                    }

                    .adtm_column_wrap_td_width_40 {
                        width: calc(100% / 2.5 - 2 * #{#{$gutterTd}}px);
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 2;
                            column-gap: 40px;
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_50 {
                        width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 2;
                            column-gap: 40px;
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_60 {
                        width: calc(60% - 2 * #{#{$gutterTd}}px);
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 3;
                            column-gap: 40px;
                            @include tablet() {
                                column-count: 2;
                            }
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_66 {
                        width: calc(100% / 3 * 2 - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 3;
                            column-gap: 40px;
                            @include tablet() {
                                column-count: 2;
                            }
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_70 {
                        width: calc(70% - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 3;
                            column-gap: 40px;
                            @include tablet() {
                                column-count: 2;
                            }
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_75 {
                        width: calc(75% - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 3;
                            column-gap: 0;
                            @include tablet() {
                                column-count: 2;
                            }
                            @include mobile() {
                                column-count: 1;
                            }
                        }
                    }

                    .adtm_column_wrap_td_width_80 {
                        width: calc(80% - 2 * #{#{$gutterTd}}px);
                        @include small-desktop() {
                            width: calc(100% / 2 - 2 * #{#{$gutterTd}}px);
                        }
                        @include mobile() {
                            width: 100%;
                        }

                        .adtm_elements {
                            column-count: 4;
                            column-gap: 40px;
                            @include tablet() {
                                column-count: 2;
                            }
                            @include mobile() {
                                column-count: 1;
                                column-gap: 0;
                            }
                        }
                    }

                    .column_wrap_title {
                        @include redHatDisplay18(700, $white);
                        display: flex;
                        padding: 10px 0;
                        border-bottom: 1px solid $white;

                        a {
                            @include redHatDisplay18(700, $white);
                            text-decoration: none;
                            display: flex;
                            align-items: flex-end;
                            width: 100%;
                        }

                        svg {
                            @include font-size-lh(23, 23);
                            margin-right: 15px;
                        }

                        img {
                            width: auto;
                            max-width: 40px;
                            margin-right: 15px;
                            object-fit: contain;
                            object-position: 50% 50%;
                        }

                        &_center {
                            text-align: center;

                            a {
                                margin: 0 auto;
                            }
                        }

                        &_no_sub {
                            @include redHatDisplay14(400, $white);
                            letter-spacing: 0;

                            a {
                                @include redHatDisplay14(400, $white);
                                letter-spacing: 0;

                                svg {
                                    @include font-size-lh(20, 20);
                                    margin-right: 10px;
                                }

                                img {
                                    width: 20px;
                                    margin-right: 10px;
                                    object-fit: contain;
                                    object-position: 50% 50%;
                                }
                            }
                        }
                    }
                }

                .adtm_elements {
                    display: block;

                    li {
                        width: 100%;
                        padding: 10px 0;
                        border-bottom: 1px solid $white;

                        a {
                            @include redHatDisplay14(400, $white);
                            text-decoration: none;

                            svg {
                                @include font-size-lh(20, 20);
                                margin-right: 10px;
                            }

                            img {
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            &:hover {
                .a-niveau1:after {
                    width: 50px;
                    @include tablet() {
                        width: 3px;
                        height: 20px;
                    }
                }

                .adtm_sub {
                    z-index: 99;
                    @include tablet() {
                        display: block;
                    }
                    @include tablet-up() {
                        opacity: 1;
                        height: auto;
                        padding: 20px 0 45px 0;
                        pointer-events: auto;
                        transition: all 0.3s linear 0.5s;
                    }
                }
            }
        }

        .advtm_menu_toggle {
            display: none;
        }
    }
}
.cta {
    display: flex;
    justify-content: center;

    .push--links--item & {
        justify-content: flex-start;
    }

    .cms--content--actions & {
        margin-left: 20px;
    }

    .colissimo-pickup-point-address & {
        justify-content: flex-start;
    }

    &--link {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 220px;
        min-height: 53px;
        padding: 0 15px;
        border: none;
        border-radius: 53px;
        transition: all 0.3s;

        .push--links--item & {
            justify-content: flex-start;
            align-items: flex-start;
            min-width: initial;
            min-height: initial;
            padding: 0;
            border-radius: 0;
        }

        .products-list--product--actions--cta &,
        .push--category--cta & {
            min-width: 50px;
            min-height: 50px;
            border-radius: 50%;
            padding: 0;
        }

        .category--heading--cta & {
            border-radius: 0;
        }

        .product--actions & {
            justify-content: flex-start;
            min-width: 250px;
        }

        .product--actions--add-to-cart & {
            cursor: pointer;
        }

        .product--actions--download &,
        .product--actions--print & {
            justify-content: center;
        }

        .product--images--cta & {
            justify-content: space-between;
            min-width: 230px;
            border-radius: 0 53px 53px 0;
            padding: 10px 12px 10px 18px;
        }

        .product--configurator--attributes--action & {
            width: 100%;
            min-height: 35px;
            border-radius: 0 35px 35px 0;
        }

        .colissimo-pickup-point-address & {
            cursor: pointer;
        }

        .cta--grey & {
            background-color: $grey;
        }

        .cta--white & {
            background-color: $white;
            border: 2px solid $white;

            &:hover {
                .push--intro--item & {
                    background-color: $color1;

                    .cta--txt {
                        color: $white;
                    }
                }
            }

            .checkout--addresses--action &,
            .checkout--action &,
            .product--actions--print & {
                border-color: $grey;
            }

            .checkout--addresses--action.new & {
                border-color: $color1;
            }
        }

        .cta--green & {
            background-color: $color2;
            border: 2px solid $color2;

            &:hover {
                .push--intro--item & {
                    background-color: $white;

                    .cta--txt {
                        color: $color2;
                    }
                }
            }
        }

        .cta--green-dark & {
            background-color: $color1;
            border: 2px solid $color1;

            .products-list--product--add-to-cart--cta:hover & {
                background-color: $white;
            }
        }
    }

    &--txt {
        margin: 0;
        transition: all 0.3s;

        .products-list--product--add-to-cart & {
            order: 2;
        }

        .product--actions &,
        .checkout--action & {
            order: 2
        }

        .product--actions--back & {
            text-transform: uppercase;
        }

        .push--links--item & {
            @include redHatDisplay18(900, $white);
            @include line-height(30);
            max-width: 300px;
        }

        .push--links--item[data-color="white"] & {
            color: $color2;
        }

        .cta--grey & {
            @include redHatDisplay15(700, $color1);
            @include line-height(20);

            .product--images--cta & {
                @include redHatDisplay22(700, $color1);
                text-transform: uppercase;
            }
        }

        .cta--white & {
            @include redHatDisplay15(700, $blue);
            @include line-height(20);

            .checkout--addresses--action &,
            .checkout--action &,
            .product--actions--print &,
            .product--actions--ask-a-quote &{
                color: $color1;
            }
        }

        .cta--green &,
        .cta--green-dark & {
            @include redHatDisplay15(700, $white);
            @include line-height(20);

            .products-list--product--add-to-cart--cta:hover & {
                color: $color1;
            }
        }

        .cta--green & {
            .category--heading--cta &,
            .product--configurator--attributes--action & {
                @include redHatDisplay18(700, $white);
                text-transform: uppercase;
            }
        }
    }

    &--picto {
        transition: all 0.3s;

        .products-list--product--add-to-cart & {
            margin-right: 10px;
            order: 1;
        }

        .product--actions &,
        .checkout--action & {
            margin-right: 10px;
            order: 1;
        }

        .product--actions--back & {
            height: 19px;
        }

        .cta--green & {
            background-color: $color2;
        }

        .cta--green-dark & {
            background-color: $color1;

            .products-list--product--add-to-cart--cta:hover & {
                background-color: $white;

                svg {
                    .cls-1 {
                        fill: $color1;
                    }
                }
            }
        }

        .products-list--product--actions--cta &,
        .push--category--cta & {
            overflow: hidden;

            svg {
                transform: translateX(-4px);
                transition: all 0.3s;

                .cls-1 {
                    fill: $white;
                }
            }
        }

        .products-list--product--link:hover &,
        .push--category--link:hover & {
            svg {
                transform: translateX(0);
            }
        }

        .push--links--item & {
            height: 18px;
            margin-top: 6px;
            margin-left: 10px;
            overflow: hidden;

            svg {
                transform: translateX(-6px);
                transition: all 0.3s;
            }
        }

        .push--links--item[data-color="color1"] &,
        .push--links--item[data-color="color2"] & {
            svg {
                .cls-1 {
                    fill: $white;
                }
            }
        }

        .push--links--item--cta:hover & {
            svg {
                transform: translateX(0);
            }
        }

        .product--actions--print & {
            svg {
                transform: scale(1.3);

                .cls-1 {
                    fill: $white;
                }

                .cls-2 {
                    fill: $color1;
                }
            }
        }

        svg {
            .cta--white & {
                color: $color1;
            }
        }
    }
}
$sans-serif: Tahoma, Arial, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: Arial, Tahoma, sans-serif;;
$times: $serif;
@mixin arial($font-weight: 400) {
    font-family: $arial;
    font-weight: $font-weight;
}
@mixin times($font-weight: 400) {
    font-family: $times;
    font-weight: $font-weight;
}
$redHatDisplay: 'Red Hat Display', $sans-serif;
@mixin redHatDisplay($font-weight: 400) {
    font-family: $redHatDisplay;
    font-weight: $font-weight;
}

@mixin redHatDisplay70($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(70, 80);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay50($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(50, 60);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay38($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(38, 50);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay30($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(30, 38);
    color: $color;
}

@mixin redHatDisplay24($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(24, 45);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay22($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(22, 28);
    color: $color;
}

@mixin redHatDisplay18($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(18, 18);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay16($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(16, 16);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay15($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(15, 16);
    color: $color;
    @include letter-spacing(50);
}

@mixin redHatDisplay14($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(14, 14);
    color: $color;
}

@mixin redHatDisplay12($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(12, 12);
    color: $color;
}

@mixin redHatDisplay10($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(10, 10);
    color: $color;
}

@mixin redHatDisplay8($font-weight: 400, $color: $black) {
    @include redHatDisplay($font-weight);
    @include font-size-lh(8, 10);
    color: $color;
    @include letter-spacing(100);
}
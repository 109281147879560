.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;

    .body--product--configurator &,
    body.product & {
        @media print {
            display: none;
        }
    }

    .st_banner_text {
        p  {
            margin: 0;
            padding: 10px 0 0 0;
        }
    }

    #layer_cart,
    .layer_cart_overlay {
        display: none !important;
    }

    &--top {
        position: relative;
        z-index: 1;
        background-color: $color1;

        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 120px;
            transition: all 0.3s;
            @include tablet {
                position: relative;
                height: 70px;
                padding-left: 60px;
            }

            @include tablet-up {
                body.scrolled & {
                    height: 90px;
                }
            }
        }
    }

    &--logo {
        width: 100%;
        max-width: 195px;
        transition: all 0.3s;
        @include mq(1024px, 1550px) {
            margin-left: 4%;
            max-width: 165px;
        }
        @include tablet {
            max-width: 135px;
        }

        body.scrolled & {
            max-width: 135px;
        }

        svg {
            width: 100%;
        }
    }

    &--menu {
        width: calc(100% - 300px);
        height: 100%;
        @include small-desktop($w1024) {
            width: auto;
        }
        @include mobile() {
            width: auto;
        }

        &--nav {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
        }

        &--link {
            text-decoration: none;
            display: flex;
            height: 100%;
        }

        &--item {
            height: 100%;
            margin: 0 25px;

            &--inner {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 30px;
                    left: 0;
                    width: 0;
                    height: 3px;
                    background-color: $white;
                    border-radius: 3px;
                    transition: all 0.3s;
                }

                &.active,
                &:hover {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        &--picto {
            max-width: 66px;
        }

        &--txt {
            @include redHatDisplay18(400, $white);
            margin: 0 0 0 7px;
        }
    }

    &--submenu {
        position: absolute;
        top: 120px;
        left: 0;
        width: 100%;
        z-index: 15;
        background-color: $color1;
        padding-top: 45px;
        padding-bottom: 30px;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &--inner {
            display: flex;
            justify-content: space-between;
            width: $w1150;
            max-width: 100%;
            margin: 0 auto;
        }

        &--product {
            width: 334px;
            margin-top: 13px;

            &--link {
                text-decoration: none;
            }

            &--image {
                width: 100%;
                height: 175px;
                background-color: $white;

                img {
                    @include object();
                }
            }

            &--name {
                @include redHatDisplay18(700, $white);
                text-align: center;
                text-transform: uppercase;
                margin-top: 5px;
            }
        }

        &--nav {
            display: flex;
            width: 720px;
            margin: 0;
            padding: 0;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
        }

        &--item {
            width: 313px;
            border-bottom: 1px solid $white;
        }

        &--link {
            text-decoration: none;
        }

        &--txt {
            @include redHatDisplay18(400, $white);
            margin: 0;
            padding: 13px 0;
        }
    }

    &--account,
    &--cart,
    &--contact {
        background-color: $color2;
        position: absolute;
        top: 100%;
        padding: 6px 10px 12px 10px;
        border-radius: 0 0 20px 20px;
        @include mobile() {
            display: none;
        }

        #adtm_menu_inner & {
            display: none;
            @include tablet() {
                display: block;
                position: relative;
                top: initial;
                right: initial;
            }
        }

        &--link {
            display: flex;
            align-items: center;
            text-decoration: none;

            > div {
                display: flex;
                align-items: flex-end;
                justify-content: center;

                .adtm_menu_container & {
                    @include tablet() {
                        flex-direction: row;
                    }
                }
            }
        }

        svg {
            width: 22px;
            height: 22px;
        }

        .adtm_menu_container & {
            p {
                @include redHatDisplay18(400, $white);
                margin: 0 0 0 10px;
            }
        }
    }

    &--cart {
        right: 90px;
        &--link {
            text-decoration: none;

            &:hover {
                p {
                    @include mobile-up() {
                        display: block;
                    }
                }
            }
        }

        p {
            transition: all 0.3s;
            @include mobile-up() {
                @include redHatDisplay15(700, $white);
                padding: 0 20px 0 5px;
                margin: 0;
                display: none;
            }

            #adtm_menu_inner & {
                display: block;
            }
        }

        &--nb {
            @include redHatDisplay8(400, $color2);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 50%;
            aspect-ratio: 1;
            min-width: 15px;
            position: absolute;
            top: 0;
            right: 0;
            @include tablet() {
                right: -10px;
                .header--actions & {
                    right: initial;
                    left: -10px;
                }
            }
        }
    }

    &--account {
        right: 25px;

        &--name {
            display: none;
        }
    }

    //&--contact {
    //    right: 25px;
    //
    //    &--name {
    //        display: none;
    //    }
    //}

    &--actions {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        @include tablet {
            margin-top: 0;
        }
    }

    &--informations {
        background-color: $color2;
        padding: 15px;
        p {
            @include redHatDisplay(400);
            @include font-size-lh(14, 18);
            color: $white;
            text-align: center;
            margin: 0;
        }
    }
}
.st_banner_image {
    height: 100%;
}
.push--category {
    background-color: $grey-light;
    padding: 90px 15px 120px 15px;

    .container {
        width: $w1024;
    }

    &--image {
        width: 100%;
        height: 215px;
        border-radius: 10px;

        img {
            @include object();
            border-radius: 10px;
        }
    }

    &--content {
        position: relative;
    }

    &--body {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50px;
        right: 100px;
        width: calc(100% - 150px);
        transform: translateY(-50%);
        @include mobile() {
            left: 25px;
            right: 25px;
            width: calc(100% - 50px);
        }
    }

    &--picto {
        flex: 0 0 85px;
        @include mobile() {
            flex-basis: 50px;
        }
    }

    &--txt {
        flex: 0 1 100%;
        margin: 0 25px;
        padding-bottom: 15px;
        border-bottom: 1px solid $white;
        @include mobile() {
            margin: 0 15px;
        }

        &--1 {
            p {
                @include redHatDisplay24(700, $white);
                margin: 0;
                @include mobile() {
                    @include redHatDisplay18(700, $white);
                }
            }
        }

        &--2 {
            display: inline-flex;

            p {
                @include redHatDisplay38(700, $white);
                @include line-height(45);
                margin: 0;
                @include mobile() {
                    @include redHatDisplay22(700, $white);
                }
            }
        }
    }

    &--cta {
        width: 50px;
        margin-left: 20px;
        transition: all 0.3s;
        @include mobile() {
            transform: scale(0.75);
            margin-left: 15px;
        }
    }
}
.footer {
    background-color: $color1;
    position: relative;
    z-index: 50;
    margin-top: 185px;
    @include tablet {
        margin-top: 0;
    }

    .body--product--configurator &,
    body.product & {
        @media print {
            display: none;
        }
    }

    &--inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 60px 0 75px 0;
        @include tablet {
            flex-wrap: wrap;
            padding: 60px 15px 75px 15px;
        }
    }

    &--logo-rs {
        width: calc(100% / 3);
        padding-right: 60px;
        @include tablet() {
            width: 100%;
            margin-bottom: 50px;
        }
        @include mobile() {
            padding-right: 0;
        }
    }

    &--logo {
        svg {
            width: 100%;
            max-width: 175px;
            height: auto;
        }
    }

    &--txt {
        @include redHatDisplay(400);
        color: $white;
        @include font-size-lh(14, 18);
        margin-top: 20px;
    }

    &--rs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        position: relative;

        &--header {
            &--1 {
                @include redHatDisplay(700);
                color: $white;
                @include font-size-lh(15, 20);
                text-transform: uppercase;
            }
        }

        &--items {
            display: flex;
            justify-content: center;
        }

        &--item {
            margin: 0 5px;

            &--link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 38px;
                height: 38px;
                background-color: $color2;
                border-radius: 50%;
            }
        }
    }

    &--links {
        width: calc(100% / 3 * 2);
        @include tablet() {
            width: 100%;
        }

        &--level {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        &--item {
            width: calc(100% / 2 - 80px);
            margin: 0 40px;
            @include mobile {
                width: 100%;
                margin: 0;
            }
        }

        &--link {
            @include redHatDisplay15(400, $white);
            text-decoration: none;
            display: flex;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid $grey;
            cursor: pointer;

            .footer--links--item:nth-child(1) &,
            .footer--links--item:nth-child(2) & {
                @include redHatDisplay(700);
                @include font-size-lh(18, 23);
                margin-bottom: 30px;
            }
        }

    }

    &--bottom {
        background-color: $white;
        padding: 20px 0;
        @include tablet() {
            padding: 20px 15px;
        }
    }

    &--legals {
        @include redHatDisplay14(400, $color1);
        text-align: center;

        a {
            @include redHatDisplay14(400, $color1);
            text-decoration: none;
        }
    }
}
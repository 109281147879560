@import "../plugins/jquery.fancybox";

.fancybox-outer,
.fancybox-inner {
    position: inherit !important;
}

.fancybox-bg {
    background-color: $black;
    opacity: .8;
}

#map-wrapper {
    position: relative;
    border: 1px solid $color1;
    padding-bottom: 52.50%;
    margin-top: 15px;
    margin-bottom: 32px;
    @include tablet() {
        padding: 0;
    }

    #map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        @include tablet() {
            position: relative;
            padding-bottom: 52.50%;
        }
        @include mobile() {
            position: relative;
            padding-bottom: 75%;
        }
    }

    .localiser {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $color1;
        color: $white;
        padding: 15px 20px;
        z-index: 10;
        font-size: 0;
        display: flex;
        align-items: center;
        @include tablet() {
            position: relative;
            display: none;
        }

        label {
            @include redHatDisplay22(400, $white);
            text-transform: uppercase;
            margin-right: 20px;
        }

        input[type="text"] {
            height: 32px;
            @include redHatDisplay18(400, $color1);
            border: none;
            padding: 5px;
            text-align: center;
            width: 100%;
            max-width: 320px;
            @include tablet() {
                max-width: 225px;
            }
        }

        button {
            border: none;
            padding: 0;
            width: 32px;
            height: 32px;
            background-color: $color2;
            text-align: center;

            i {
                width: 32px;
                height: 32px;
                color: $white;
                @include font-size-lh(16, 32);
            }
        }
    }

    .side-map {
        position: absolute;
        top: 72px;
        right: 20px;
        z-index: 10;
        @include tablet() {
            position: relative;
            top: inherit;
            right: inherit;
            overflow: hidden;
        }

        .box {
            @include box-shadow(0 0 10px 0 rgba($black, .7));
            background-color: $white;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            @include tablet() {
                @include box-shadow(0 0 10px 0 rgba($black, 0));
                float: left;
                width: 100%;
                margin: 0;
                border-top: 1px solid $white;
                &:last-child {
                    display: none;
                }
            }
            @include mq(null, 570px) {
                width: 100%;
            }

            .box-title {
                background-color: $color1;
                text-align: center;
                text-transform: uppercase;
                @include redHatDisplay18(700, $white);
                padding: 7px 5px 5px 5px;
            }

            .box-content {
                padding: 10px;

                .field {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include tablet() {
                        &:nth-child(2) {
                            display: none;
                        }
                    }

                    .label {
                        label {
                            @include redHatDisplay12(700, $color1);
                            text-transform: uppercase;
                        }
                    }

                    &.checkbox {
                        .label {
                            label {
                                @include redHatDisplay12(400, $color1);
                                text-transform: none;

                                input {
                                    display: inline-block;
                                    width: auto;
                                    vertical-align: middle;
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                }

                select,
                input {
                    width: 100%;
                    background-color: $grey;
                    @include redHatDisplay14(600, $color1);
                    border: none;
                    padding: 5px;
                    outline: 0;
                }

                ul.pins {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        position: relative;
                        margin: 5px 0;

                        .tooltip {
                            @include font-size-lh(14, 18);
                            text-align: left;
                            text-transform: initial;
                            position: absolute;
                            top: 50%;
                            right: 100%;
                            color: $white;
                            background-color: rgba($black, 0.9);
                            border-radius: 5px;
                            padding: 15px;
                            margin-right: 5px;
                            width: 300px;
                            overflow: hidden;
                            white-space: normal;
                            transform: translateY(-50%);
                            transition: all 0.3s;
                            opacity: 0;
                            pointer-events: none;
                            @include tablet() {
                                position: relative;
                                top: inherit;
                                right: inherit;
                                color: $black;
                                background-color: transparent;
                                width: auto;
                                overflow: inherit;
                                clear: both;
                                opacity: 1;
                                padding: 2px 5px 5px 5px;
                                margin: 0;
                                transform: translateY(0);
                            }
                        }

                        span.pin {
                            width: 18px;
                            height: 25px;
                            background-image: url('/blog/wp-content/themes/amsterdamair/assets/img/pins.png');
                            margin-right: 10px;

                            &.pin-velo {
                                background-position: 0 0;
                            }

                            &.pin-avis {
                                background-position: 0 -26px;
                            }

                            &.pin-relation {
                                background-position: 0 -52px;
                            }

                            &.pin-parrain {
                                background-position: 0 -78px;
                            }
                        }

                        span.txt {
                            @include redHatDisplay12(600, $color1);
                            text-transform: uppercase;
                        }

                        &:hover {
                            .tooltip {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.my-amsterdamer-lazyload {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.5);
    display: none;

    .my-amsterdamer-loader-ellips {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .loader-ellips {
        font-size: 20px; /* change size here */
        position: relative;
        width: 4em;
        height: 1em;
        margin: 10px auto;
    }

    .loader-ellips__dot {
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 0.5em;
        background: $color2;
        position: absolute;
        animation-duration: 0.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;

        &:nth-child(1), &:nth-child(2) {
            left: 0;
        }

        &:nth-child(3) {
            left: 1.5em;
        }

        &:nth-child(4) {
            left: 3em;
            animation-name: reveal;
            animation-direction: reverse;
        }

        &:nth-child(1) {
            animation-name: reveal;
        }

        &:nth-child(2), &:nth-child(3) {
            animation-name: slide;
        }
    }
}

#avis-aventures {
    padding: 0;

    ul {
        padding: 0;
        margin: 0;
        font-size: 0;

        li {
            @include redHatDisplay14(400, $color1);
            display: inline-block;
            vertical-align: top;
            width: 25%;
            margin: 20px 0;
            @include mq(null, 1023px) {
                width: 33.333%;
            }
            @include mq(null, 600px) {
                width: 50%;
            }
            @include mq(null, 480px) {
                width: 100%;
            }

            .inner {
                border: 2px solid $white;
                padding: 10px;
                @include transition(all, 0.3s);

                &:hover {
                    border: 2px solid $color2;
                }
            }

            .name {
                @include redHatDisplay(400);
                @include dotdotdot(16, 20, 1);
                color: $color2;
                margin-bottom: 4px;
                position: relative;
                a {
                    color: $color2;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                span {
                    text-transform: uppercase;
                }

                .thumbs-action {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $color2;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .title {
                @include redHatDisplay(600);
                @include dotdotdot(14, 18, 2);
                text-transform: uppercase;
                color: $color1;
                margin-bottom: 5px;
            }

            .img {
                position: relative;
                width: 100%;
                height: 142px;
                margin-bottom: 5px;

                a {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                img {
                    @include object();
                }
            }

            .rating {
                height: 13px;
                margin-bottom: 10px;

                .note {
                    width: 63px;
                    height: 13px;
                    background-image: url('/blog/wp-content/themes/amsterdamair/assets/img/note.png');
                    background-repeat: no-repeat;
                    background-position: 0 0;

                    &.note-1 {
                        background-position: 0 -13px;
                    }

                    &.note-2 {
                        background-position: 0 -26px;
                    }

                    &.note-3 {
                        background-position: 0 -39px;
                    }

                    &.note-4 {
                        background-position: 0 -52px;
                    }

                    &.note-5 {
                        background-position: 0 -65px;
                    }
                }
            }

            .product-name {
                @include redHatDisplay(400);
                @include dotdotdot(16, 20, 2);
                margin-bottom: 5px;

                a {
                    color: $color2;
                    text-decoration: underline;
                    display: block;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .description {
                margin-bottom: 5px;

                p {
                    @include redHatDisplay(400);
                    @include dotdotdot(17, 21, 4);
                    margin: 0;
                    color: $color1;
                }
            }

            .action {
                margin-top: 5px;

                a {
                    @include redHatDisplay14(600, $white);
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    display: block;
                    background-color: $color1;
                    padding: 10px 5px;
                    transition: all 0.3s;
                    border-radius: 100px;

                    &:hover {
                        background-color: $color2;
                    }
                }

                span {
                    @include redHatDisplay14(600, $white);
                    text-align: center;
                    text-transform: uppercase;
                    background-color: transparent;
                    display: block;
                    padding: 7px 5px 5px 5px;
                    transition: all 0.3s;
                }
            }
        }
    }
}

#map {
    .info-window-content {
        max-width: 390px;
        position: relative;

        .iw-close-btn {
            @include font-size-lh(22, 30);
            color: $color1;
            position: absolute;
            top: 0;
            right: 0;
            display: none;
        }

        .product-name {
            a {
                color: $color2;
                text-decoration: none;
                transition: all 0.3s;
                &:hover {
                    color: $color2;
                    text-decoration: underline;
                }
            }
        }

        .name {
            @include redHatDisplay(400);
            @include dotdotdot(16, 20, 1);
            color: $color2;
            margin-bottom: 4px;
            a {
                color: $color2;
                text-decoration: none;
                transition: all 0.3s;
                &:hover {
                    color: $color1;
                    text-decoration: underline;
                }
            }

            span {
                text-transform: uppercase;
            }
        }

        .title {
            @include redHatDisplay(600);
            @include dotdotdot(18, 22, 1);
            text-transform: uppercase;
            color: $color1;
            margin-bottom: 5px;
        }

        .img {
            display: none;
        }

        .rating {
            display: none;
            height: 13px;
            margin-bottom: 10px;

            .note {
                width: 63px;
                height: 13px;
                background-image: url('/blog/wp-content/themes/amsterdamair/assets/img/note.png');
                background-repeat: no-repeat;
                background-position: 0 0;

                &.note-1 {
                    background-position: 0 -13px;
                }

                &.note-2 {
                    background-position: 0 -26px;
                }

                &.note-3 {
                    background-position: 0 -39px;
                }

                &.note-4 {
                    background-position: 0 -52px;
                }

                &.note-5 {
                    background-position: 0 -65px;
                }
            }
        }

        .inner.comment {
            .rating {
                display: block;
            }
        }

        .description {
            margin-bottom: 5px;

            p {
                @include redHatDisplay(400);
                @include dotdotdot(17, 21, 2);
                color: $color1;
                margin: 0;
            }
        }

        .action {
            margin-top: 5px;
            display: inline-block;
            vertical-align: middle;

            &:last-child {
                margin-left: 5px;
            }

            a {
                @include redHatDisplay14(600, $white);
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                background-color: $color1;
                display: block;
                padding: 10px 15px;
                transition: all 0.3s;
                border-radius: 100px;

                &:hover {
                    background-color: $color2;
                }
            }
        }
    }
}

#map-legend-info {
    @include font-size-lh(12, 14);
    text-align: center;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    padding-top: 5px;
}

ul.notes-echelle {
    font-family: $sans-serif;
    margin: 0 0 0 10px;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    list-style: none;

    &.js {
        li {
            display: inline-block;
            width: 25px;
            height: 25px;
        }

        input {
            position: absolute;
            left: -999%;
        }
    }

    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        list-style: none;

        label {
            display: block;
            text-align: center;
            @include font-size-lh(10, 24);
            background-image: url('../img/note/noter.png');
            background-repeat: no-repeat;
            background-position: center top;
            color: $red;
            text-indent: -9999px;
            cursor: pointer;
        }

        &.note-off label {
            background-position: center bottom;
        }

        &.note-checked {
            font-weight: bold;
        }

        &.note-focus {
            outline: none;
        }
    }
}

#avis-aventures-list {
    ul {
        padding: 10px 0;
        margin: 20px 0;
        list-style: none;
        border-top: 1px solid $color1;

        li {
            position: relative;
            padding: 15px 10px;
            margin: 0 0 10px 0;
            transition: all 0.3s;

            &:before {
                content: "";
                position: absolute;
                top: -10px;
                right: -10px;
                bottom: -10px;
                left: -10px;
                border: 2px solid $white;
                transition: all 0.3s;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-bottom: 1px solid $color1;
                transition: all 0.3s;
            }

            &:hover {
                &:before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border: 2px solid $color2;
                }

                &:after {
                    border-bottom: 0 solid $white;
                }

                .title {
                    color: $color2;
                }
            }
        }

        .inner {
            position: relative;
            z-index: 2;

            .thumbs-action {
                position: absolute;
                top: 0;
                right: 0;
                color: $color2;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }

            .title {
                @include redHatDisplay22(700, $color1);
                text-transform: uppercase;
                margin-bottom: 5px;
                transition: all 0.3s;
            }

            .author {
                @include redHatDisplay14(400, $color1);
                a {
                    color: $color1;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .product {
                a {
                    @include redHatDisplay14(400, $color2);
                    text-decoration: none;
                    border-bottom: 1px solid $color2;
                }
            }

            .description {
                @include redHatDisplay14(400, $color1);
                margin: 10px 0;
            }

            .description-img {
                @include clearfix;

                .description {
                    float: left;
                    width: calc(100% - 320px);
                    padding-right: 20px;
                    @include mq(null, 768px) {
                        width: 100%;
                        padding-right: 0;
                    }
                }

                .img-wrap {
                    @include mq(null, 768px) {
                        width: 50%;
                    }
                    @include mq(null, 664px) {
                        width: 100%;
                    }
                }
            }

            .rating {
                height: 13px;
                margin: 5px 0;

                .note {
                    width: 63px;
                    height: 13px;
                    background-image: url('/blog/wp-content/themes/amsterdamair/assets/img/note.png');
                    background-repeat: no-repeat;
                    background-position: 0 0;

                    &.note-1 {
                        background-position: 0 -13px;
                    }

                    &.note-2 {
                        background-position: 0 -26px;
                    }

                    &.note-3 {
                        background-position: 0 -39px;
                    }

                    &.note-4 {
                        background-position: 0 -52px;
                    }

                    &.note-5 {
                        background-position: 0 -65px;
                    }
                }
            }

            .image {
                position: relative;
                width: 100%;
                height: 200px;
                border: 1px solid $grey-dark;
                border-bottom: 5px solid $color2;
                margin: 0 0 20px 0;

                a {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                img {
                    @include object();
                }
            }

            .img-wrap {
                float: left;
                width: 320px;
                max-width: 100%;
            }

            .images {
                font-size: 0;
                margin: 0 -8px;
                overflow: hidden;

                .img-wrap {
                    float: none;
                    display: inline-block;
                    vertical-align: top;
                    width: 33.333%;
                    padding: 8px;
                    min-width: 320px;
                    @include mq(null, 984px) {
                        width: 50%;
                    }
                    @include mq(null, 664px) {
                        width: 100%;
                    }

                    &.ambassador-wrap {
                        float: right;
                    }
                }
            }

            .ambassador {
                a {
                    @include redHatDisplay14(600, $white);
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    display: block;
                    padding: 10px;
                    background-color: $color1;
                    transition: all 0.3s;
                    border-radius: 100px;

                    &:hover {
                        background-color: $color2;
                    }
                }
            }
        }
    }
}

.return-to-map {
    display: inline-block;

    a {
        @include redHatDisplay14(600, $white);
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        display: block;
        padding: 10px;
        background-color: $color1;
        transition: all 0.3s;
        border-radius: 100px;

        &:hover {
            background-color: $color2;
        }
    }
}

#post-footer {
    margin-top: 10px;
}

.btn-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 0 0;

    .action {
        width: auto;
        margin: 0 15px;

        a {
            @include redHatDisplay14(600, $white);
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            display: inline-block;
            background-color: $color1;
            padding: 10px 20px;
            border-radius: 100px;
            transition: all 0.3s;

            &:hover {
                background-color: $color2;
            }
        }
    }
}

.page-template-my-amsterdamer {
    #offre-ambassadeur {
        max-width: 860px;

        .center {
            text-align: center;
            margin: auto;
        }

        .h2, .h3 {
            text-align: center;
            color: $color2;
        }

        .h2 {
            @include font-size-lh(34, 38);
        }

        .h3 {
            @include font-size-lh(24, 28);
        }

        table {
            margin: auto;

            tr {
                td {
                    padding: 5px 15px;
                    border: 1px solid $black;
                }
            }
        }
    }
}
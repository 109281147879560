.page--checkout {
    @include tablet() {
        padding: 0 15px;
    }
    @include mobile() {
        padding-top: 20px;
    }
}
.checkout {
    &--header {
        margin-bottom: 65px;

        &--title {
            @include redHatDisplay38(700, $color1);
            text-align: center;
            text-transform: uppercase;
            margin: 0;

            span {
                color: $color2;
            }
        }
    }

    &--steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 40px;

        &:before {
            content: '';
            background-color: $border-account;
            width: 100%;
            height: 1px;
            position: absolute;
            top: 21px;
            left: 0;
        }
    }

    &--step {
        flex: 0 1 140px;
        display: flex;
        justify-content: center;
        position: relative;
        padding: 50px 15px 0 15px;
        @include mobile() {
            padding: 50px 10px 0 10px;
        }

        &.step_current {
            &:after {
                background-color: $color2;
            }

            > a,
            > span {
                color: $color2;
            }
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            top: 0;
            width: 40px;
            height: 40px;
            background-color: $white;
            border: 1px solid $border-account;
            border-radius: 50%;
        }

        &:after {
            width: 16px;
            height: 16px;
            background-color: $grey-dark;
            border-radius: 50%;
            top: 12px;
        }

        > a,
        > span {
            @include redHatDisplay18(700, $grey-dark);
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            @include mobile() {
                @include redHatDisplay12(700, $grey-dark);
                > span {
                    display: block;
                }
            }
        }
    }

    &--actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 60px;
        #submitReorder & {
            margin-top: 0;
            margin-bottom: 60px;
        }
    }

    &--action {
        margin: 10px 25px;

        button {
            cursor: pointer;
        }
    }

    &--addresses {
        display: flex;
        justify-content: center;
        margin-top: 65px;
        @include mobile() {
            flex-wrap: wrap;
        }

        &--item {
            width: 100%;
            margin: 0 50px;
            position: relative;

            &.bloc-livraison:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: $grey-dark;
                @include mobile() {
                    width: 100%;
                    height: 1px;
                    top: initial;
                    bottom: 0;
                }
            }

            .address {
                list-style: none;

                li {
                    @include redHatDisplay14(400, $color1);
                    font-style: italic;
                }
            }

            .head-adresse {
                margin-bottom: 20px;
            }

            .page-subheading {
                @include redHatDisplay22(400, $color2);
                font-style: normal;
                margin: 0;
            }

            .address_update {
                display: none;
            }
        }

        &--actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 30px 50px 0 50px;
        }

        &--options {
            background-color: $grey;
            padding: 20px 30px;
        }

        &--options {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
        }

        &--option {
            &:first-child {
                margin-bottom: 15px;
            }

            label {
                @include redHatDisplay14(700, $color1);
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            textarea {
                @include redHatDisplay14(400, $color1);
                font-style: italic;
                width: 100%;
                height: 90px;
                background-color: $white;
                border: 1px solid $border-account;
                padding: 10px;
                @include placeholder($grey-dark);
            }
        }
    }

    &--delivery {
        &--footer {
            background-color: $grey;
            padding: 0 30px;

            &.first {
                margin-top: 30px;
                padding-top: 20px;
            }

            &.last {
                padding-bottom: 20px;
            }

            .carrier_title {
                @include redHatDisplay18(400, $color2);
                margin: 0;
            }

            .checkbox {
                padding-top: 15px;
            }

            label {
                position: relative;

                input {
                    border: 0;
                    clip: rect(0 0 0 0);
                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;
                    overflow: hidden;
                    position: absolute;

                    &:checked + span svg {
                        display: block;
                    }
                }

                span {
                    color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    background-color: $color2;
                    border: 1px solid $color2;
                    position: absolute;
                    left: 0;

                    svg {
                        display: none;
                    }
                }

                p {
                    @include redHatDisplay14(400, $color1);
                    margin: 0 0 0 30px;

                    a {
                        color: $color2;
                    }
                }
            }
        }
    }
}

#order-detail-content {
    table {
        border-collapse: collapse;
        border-spacing: 0;

        &#cart_summary {
            width: 100%;

            thead {
                display: none;
            }

            tfoot {
                border-top: 1px solid $border-account;
            }

            tbody {
                tr {
                    border-top: 1px solid $border-account;

                    &:first-child {
                        border-top-color: $white;

                        td {
                            padding: 10px 0;
                            @include mobile() {
                                display: none;
                            }
                            .page--account--favorites-carts & {
                                padding: 0;
                            }
                        }
                    }

                    &.row-product-detail {
                        border-top-color: $white;
                        td {
                            @include redHatDisplay14(400, $color1);
                            padding: 0 35px 30px 35px;
                        }
                    }

                    td {
                        padding: 30px 0;
                    }
                }

                .cart_discount_delete div {
                    @include redHatDisplay12(700, $color1);
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    background-color: $grey;
                    border: none;
                    border-radius: 50%;
                    margin: 0 auto;
                }

                .cart_delete,
                .price_discount_del {
                    width: 50px;
                    @include mobile() {
                        margin: 15px auto 0;
                    }
                    div {
                        display: flex;
                        justify-content: center;
                        padding: 0 10px;
                    }

                    a {
                        text-decoration: none;
                        display: block;
                        width: 24px;
                        height: 35px;
                        background-image: url('#{$imgPath}/pictos/picto-delete.svg');
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: contain;
                        transition: all 0.3s;

                        &:hover {
                            background-image: url('#{$imgPath}/pictos/picto-delete-hover.svg');
                        }
                    }
                }

                .cart_product {
                    width: 315px;
                    .page--account--favorites-carts & {
                        width: 235px;
                    }
                    @include mobile() {
                        display: block;
                    }

                    a {
                        display: block;
                        height: 145px;

                        img {
                            @include object(cover);
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .cart_description,
                .cart_discount_price_unit {
                    padding: 30px 35px 0 35px;
                    @include mobile() {
                        display: block;
                        padding: 15px 20px;
                    }

                    .product-name,
                    .discount-name {
                        @include redHatDisplay18(700, $color1);
                        text-transform: uppercase;
                    }

                    .product-availability,
                    .price-discount {
                        @include redHatDisplay14(400, $color1);
                        margin-top: 5px;

                    }

                    .actions {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: 5px;
                    }

                    .subline {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: 15px;
                        @include tablet-up() {
                            display: none;
                        }
                    }

                    .show-detail,
                    .show-product {
                        @include redHatDisplay14(700, $color2);
                        text-decoration: none;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;

                        span {
                            @include redHatDisplay14(700, $color2);
                            text-decoration: underline;
                            padding: 0;

                            &:not(:last-child) {
                                text-align: center;
                                text-decoration: none;
                                width: 20px;
                                height: 20px;
                                border: 2px solid $color2;
                                border-radius: 3px;
                                margin-right: 5px;
                            }
                        }
                    }

                    .product-detail {
                        display: none;
                    }
                }

                .cart_total,
                .cart_discount_price {
                    @include redHatDisplay22(300, $color1);
                    text-align: right;
                    width: 175px;
                    @include tablet() {
                        text-align: center;
                        margin: 15px auto 0;
                    }
                    .old-price {
                        @include redHatDisplay16(300, $color1);
                        text-decoration: line-through;
                    }
                }

                .cart_quantity {
                    width: 200px;
                    .page--account--favorites-carts & {
                        width: 120px;
                    }
                    @include mobile() {
                        margin: 0 auto;
                    }

                    span {
                        @include redHatDisplay18(700, $color1);
                        text-align: center;
                        margin: 0 20px;
                        @include mobile() {
                            display: block;
                        }
                    }

                    .cart_quantity_button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 10px;

                        .cart_quantity_down,
                        .cart_quantity_up {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            background-color: $color2;
                            border-radius: 50%;

                            svg {
                                transform: scale(0.5);
                            }

                            .cls-1 {
                                stroke: $white;
                            }
                        }

                        .cart_quantity_down {
                            .line-more {
                                display: none;
                            }
                        }

                        .cart_quantity_input {
                            @include redHatDisplay12(700, $color1);
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            background-color: $grey;
                            border: none;
                            border-radius: 50%;
                            margin: 0 10px;
                        }
                    }
                }

                td {
                    .cart_empty {
                        @include redHatDisplay14(700, $color2);
                        text-align: right;
                    }

                    &.cart_avail,
                    &.cart_unit {
                        display: none;
                    }

                    @include tablet() {
                        &.cart_quantity,
                        &.cart_delete,
                        &.cart_total {
                            display: none;
                        }
                    }
                }
            }

            .cart_voucher {
                form {
                    max-width: 650px;
                    width: 100%;
                    margin: 0 auto;
                    padding-top: 60px;

                    fieldset {
                        display: flex;
                        align-items: center;
                        background-color: $color1;
                        border: none;
                        padding: 10px 15px;
                        @include mobile() {
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                        }

                        p {
                            @include redHatDisplay18(700, $white);
                            flex: 0 1 100%;
                            padding-right: 35px;
                        }

                        label {
                            @include redHatDisplay18(700, $white);
                            text-align: right;
                            text-transform: uppercase;
                            flex: 0 1 100%;
                            padding-right: 35px;
                            @include mobile() {
                                padding-right: 0;
                                padding-bottom: 5px;
                            }
                        }

                        input {
                            @include redHatDisplay18(400, $color2);
                            background-color: $white;
                            border: 3px solid $color2;
                            flex: 0 0 196px;
                            height: 53px;
                            padding: 0 15px;
                            @include mobile() {
                                flex-basis: auto;
                                width: 100%;
                            }
                        }

                        button {
                            @include redHatDisplay18(400, $white);
                            background-color: $color2;
                            border: 3px solid $color2;
                            flex: 0 0 110px;
                            height: 53px;
                            @include mobile() {
                                flex-basis: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .cart_total_price.first > td {
                padding-top: 60px;
            }

            .total-price-div {
                max-width: 650px;
                width: 100%;
                margin: 0 auto;
                display: flex;
                align-items: center;
                padding: 25px 0 15px 0;
                border-top: 1px solid $grey;

                .page--account--favorites-carts & {
                    border-top: none;
                }

                &-label {
                    @include redHatDisplay15(700, $color1);
                    flex: 0 1 60%;
                    @include mobile() {
                        @include redHatDisplay14(700, $color1);
                        flex-basis: 50%;
                    }

                    span {
                        font-weight: 400;
                    }

                    &.last {
                        @include redHatDisplay22(700, $color2);
                        text-transform: uppercase;
                        @include mobile() {
                            @include redHatDisplay18(700, $color2);
                        }
                    }
                }

                &-amount {
                    @include redHatDisplay22(300, $color1);
                    text-align: right;
                    flex: 0 0 40%;
                    @include mobile() {
                        @include redHatDisplay22(300, $color1);
                        flex-basis: 50%;
                    }

                    &.last {
                        @include redHatDisplay30(300, $color2);
                        @include mobile() {
                            @include redHatDisplay22(300, $color2);
                        }

                        #total_price_lld {
                            @include redHatDisplay22(300, $color2);
                            @include mobile() {
                                @include redHatDisplay18(300, $color2);
                            }
                        }
                    }
                }
            }
        }
    }
}

.select-addresses-wrap {
    width: 650px;
    max-width: 100%;
    margin: 0 auto;

    &-inner {
        background-color: $color1;
        padding: 10px 15px;
    }

    .form-group {
        display: flex;
        align-items: center;
        @include mobile() {
            flex-direction: column;
        }
    }

    .addressesAreEquals {
        padding: 20px 0;
    }

    label {
        @include redHatDisplay18(700, $white);
        text-align: right;
        text-transform: uppercase;
        flex: 0 1 100%;
        padding-right: 35px;
        position: relative;
        @include mobile() {
            padding-right: 0;
            padding-bottom: 5px;
        }

        input {
            border: 0;
            clip: rect(0 0 0 0);
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            position: absolute;

            &:checked + span svg {
                display: block;
            }
        }

        span {
            color: $color2;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background-color: $white;
            border: 1px solid $white;
            position: absolute;
            left: 0;

            svg {
                display: none;
            }
        }

        p {
            @include redHatDisplay18(700, $white);
            text-align: left;
            text-transform: uppercase;
            margin: 0 0 0 30px;
        }
    }

    select {
        @include redHatDisplay14(400, $color1);
        appearance: none;
        border: none;
        width: 100%;
        height: 100%;
        padding-right: 65px;
        padding-left: 10px;
    }

    &-group {
        max-width: 365px;
        width: 100%;
        height: 35px;
        border: 1px solid $color1;
        position: relative;
    }

    .picto-pagination {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: rotate(-90deg) translateX(100%) scale(0.8);
        pointer-events: none;

        .cls-1 {
            fill: $color1;
        }
    }
}

.delivery_options_address {
    .carrier_title {
        @include redHatDisplay22(400, $color2);
        margin: 0;
    }

    .delivery_options {
        background-color: $grey;
        padding: 20px 30px;
        margin-top: 30px;
    }

    .delivery_option {
        padding: 15px 0;

        &:not(:last-child) {
            border-bottom: 1px solid $color1;
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .delivery_option_radio {
        text-align: left;
        vertical-align: top;
        width: 25px;

        label {
            position: relative;

            input {
                border: 0;
                clip: rect(0 0 0 0);
                width: 1px;
                height: 1px;
                margin: -1px;
                padding: 0;
                overflow: hidden;
                position: absolute;

                &:checked + span svg {
                    display: block;
                }
            }

            span {
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                background-color: $color2;
                border: 1px solid $color2;
                border-radius: 50%;
                position: absolute;
                left: 0;

                svg {
                    display: none;
                }
            }

            p {
                @include redHatDisplay14(400, $color1);
                margin: 0 0 0 30px;

                a {
                    color: $color2;
                }
            }
        }
    }

    .delivery_option_resume {
        @include redHatDisplay14(400, $color1);
        width: 100%;
        padding-left: 25px;

        strong {
            @include redHatDisplay15(700, $color1);
        }
    }

    .delivery_option_price {
        color: $color2;
        text-align: right;
        white-space: nowrap;
        vertical-align: bottom;
    }
}

#HOOK_PAYMENT {
    display: flex;
    flex-wrap: wrap;

    .item {
        flex: 0 0 calc(25% - 12px);
        background-color: $color1;
        border-radius: 10px;
        padding: 25px;
        margin: 6px;
        @include mobile() {
            flex: 0 0 100%;
        }

        @include mobile-up() {
            &--cb,
            &--devis {
                margin-left: 0;
            }

            &--fintecture {
                margin-right: 0;
            }
        }

        &--devis {
            background-color: $color2;
        }

        .payment_module {
            display: flex;
            justify-content: center;
        }

        button {
            appearance: none;
            background-color: transparent;
            border: none;
        }

        .mode-p {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            svg {
                @include font-size-lh(50, 50);
                color: $white;
            }

            > span {
                @include redHatDisplay22(700, $white);
                margin-top: 20px;
            }

            .info {
                margin-top: 10px;
                span {
                    @include redHatDisplay15(400, $white);
                    text-transform: uppercase;
                }
            }
        }
    }

    .paiement_cb {
        display: none;
    }
}

.payment-content {
    background-color: $grey;
    padding: 20px 30px;

    &-guest {
        margin-top: 30px;
    }

    .page-subheading {
        @include redHatDisplay30(400, $color2);
        margin-top: 0;
        margin-bottom: 20px;
    }

    p {
        @include redHatDisplay14(400, $color1);

        a,
        span,
        strong {
            color: $color2;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.cart_reassurance {
    background-color: $grey;
    padding: 20px 30px;
    display: flex;
    margin-top: 30px;

    .img {
        flex: 0 0 calc(100% / 3);
        padding-right: 50px;
    }

    .txt {
        flex: 0 0 calc(100% / 3 * 2);
    }

    p {
        @include redHatDisplay14(400, $color1);

        a,
        span,
        strong {
            color: $color2;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}